import React, { useContext } from 'react';
import { FormContext } from '../../contexts/FormContext';

const CheckboxToggle = ({ field_id, field_value, field_label, field_className, field_label_classname }) => {
    const { handleChange } = useContext(FormContext);
    return (
        <>
            <input id={field_id} type='checkbox' className={field_className} checked={field_value}
                onChange={event => handleChange(field_id, event)}
            />
            <label>{field_label}</label>
        </>
    );
}

export default CheckboxToggle;
