import React from "react";
import { Effectoa } from "../global/indexGlobal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	MenuDropDownDropLang,
	MenuDropDownEditResItem,
	MenuDropDownSaveResItem,
	MenuDropDownAdminItem,
	MenuDropDownLogoutItem,
	MenuDropDownUserItem,
	MenuDropDownMenuManagement
} from "./index";

const MenuDropDownItem = ({ title, icon, menuType }) => {

	switch (menuType) {
		case "dropdown":
			return <MenuDropDownDropLang />;
		case "ressourceEditing":
			return <MenuDropDownEditResItem title={title} icon={icon} />;
		case "ressourceSaving":
			return <MenuDropDownSaveResItem title={title} icon={icon} />;
		case "admin":
			return <MenuDropDownAdminItem title={title} icon={icon} />;		
		case "menuManagement":
			return <MenuDropDownMenuManagement title={title} icon={icon} />;	
		case "logout":
			return <MenuDropDownLogoutItem title={title} icon={icon} />;
		case "account":
			return <MenuDropDownUserItem title={title} icon={icon} />;
		default:
			return (
				<Effectoa className="dropdown-item clickable d-flex align-items-center" caption={title}>
					<FontAwesomeIcon icon={icon} className="icon me-2" />
				</Effectoa>
			);
	}
};

export { MenuDropDownItem };
