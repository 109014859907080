import React from 'react'
import { RessourceEditor } from '../../languages/RessourcesEditor';

const TableItemDetailContent = ({ data }) => {

	return (
		<>
			<RessourceEditor className={"fw-500 d-inline"} dataTranslate={data.Label}/> : {data.StartDate.slice(0,5)} - {data.EndDate.slice(0,5)}
		</>
		);
}

export default TableItemDetailContent;