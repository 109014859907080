import React, { useState, useEffect, useContext } from "react";
import MenuViewerHeader from './MenuViewerHeader'
import MenuViewerLeftContainer from './MenuViewerLeftContainer'
import MenuViewerRightContainer from './MenuViewerRightContainer'
import { LoadingContext } from '../../contexts/LoadingContext';
import MenuCreate from "./MenuCreate";
import {
	loadAllNode,
	GetMenuByMenuInformationsID,
	loadMenuInformations
} from './MenuManagementHelper';

const MenuViewer = ({ menuLoaded }) => {
	const [SELECTABLE_ITEMS, setSelectableItems] = useState([]);
	const [menu, setMenu] = useState(menuLoaded);
	const [nodes, setNodes] = useState([]);
	const [menuInformations, setMenuInormations] = useState([]);
	const [menuActive, setActiveMenu] = useState(null);
	const [IsDefault, setIsDefaultMenu] = useState(false);
	const [menuInformationID, setmenuInformationID] = useState(0);
	const [saving, setSaving] = useState(false);
	const { loading, setLoading } = useContext(LoadingContext);
	const [editState, setEditing] = useState(false);
	const [nodeId, setNodeId] = useState(0);

	async function GetMenu(value) {
		var res = await GetMenuByMenuInformationsID(value);
		if (res) {
			setMenu(res.data);
			setmenuInformationID(value);
		}
	}

	async function handleChange(event) {
		setLoading(true);
		setActiveMenu(event.value);
		GetMenu(event.value);

		if (menuInformations.length != 0) {
			var CurrentMenu = menuInformations.find(obj => {
				return obj.id === event.value;
			});
			console.log("menu" + CurrentMenu.default);
			if (CurrentMenu.default == true)
				setIsDefaultMenu(true);
			else
				setIsDefaultMenu(false);
		}
		setLoading(false);
	}

	async function load() {
		var result = await loadAllNode();
		if (result) {
			setSelectableItems(result.data);
			setNodes(result.data);
		}
		var lMenuInformations = await loadMenuInformations();
		if (lMenuInformations) {
			setMenuInormations(lMenuInformations.data);
			for (var i = 0; i < lMenuInformations.data.length - 1; i++) {
				if (lMenuInformations.data[i].default == true) {
					setActiveMenu(lMenuInformations.data[i].id);
					setIsDefaultMenu(true);
				}
				GetMenu(lMenuInformations.data[i].id);
			}
		}
	}

	useEffect(() => {
		load();
		setLoading(false);
	}, []);

	return (
		<>
			{editState ?
				<MenuCreate
					selectableItems={SELECTABLE_ITEMS}
					setSelectableItems={setSelectableItems}
					setEditing={setEditing}
					nodeId={nodeId} /> :
				<>
					<div className="page-header-filter">
						<MenuViewerHeader menuActive={menuActive}
							handleChange={handleChange}
							menuInformations={menuInformations}
							IsDefault={IsDefault}
							setIsDefaultMenu={setIsDefaultMenu}
							menuInformationID={menuInformationID} />
					</div>
					<div className="page-container">
						<div className="page-card-container width-50">
							<MenuViewerLeftContainer
								setEditing={setEditing}
								SELECTABLE_ITEMS={SELECTABLE_ITEMS}
								setNodeId={setNodeId}
							/>
						</div>

						<div className="page-card-container  width-50">
							<MenuViewerRightContainer
								setLoading={setLoading}
								menu={menu}
								nodes={nodes}
								SELECTABLE_ITEMS={SELECTABLE_ITEMS}
								setMenu={setMenu}
								menuInformationID={menuInformationID}
								setNodes={setNodes}
							/>
						</div>
					</div>
				</>
			}
		</>
	);
};
export default MenuViewer;


