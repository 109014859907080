import React from "react";
import { Navigate } from "react-router";
import useAuth from "../components/hooks/useAuth";

const ProtectedRoute = ({ children }) => {
  const { loading, loggedIn, error } = useAuth();

  if (loading) {
    return <></>;
  }
  if (error && error.response.status != 401 && error.status != 401) {
    return <Navigate to="Error" replace/>
  }

  if (loggedIn) {
    return <>{children}</>;
  } else {
    return <Navigate to="/login" replace />;
  }
};

export default ProtectedRoute;
