import React, { useEffect, useContext, useState } from "react";
import ViewContainer from "../components/container/ViewContainer";
import { ViewContext } from "../components/contexts/ViewContext";
import useAxios from "../components/hooks/useAxios";
import ShiftContainer from "../components/shift/ShiftContainer";
import { SelectBox } from "devextreme-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../components/global/table/table.css";
import PopupAddWorkSchedule from "../components/shift/PopupAddWorkSchedule";
import axios from "axios";
import { LoadingContext } from "../components/contexts/LoadingContext";
import { RessourceEditor } from "../components/languages/RessourcesEditor";
import TableMultiSelectWithCheckBox from "../components/global/table/TableMultiSelectWithCheckBox";
const urlStart = process.env.REACT_APP_URL;
const url = urlStart + "api/APIWorkSchedule/GetAllWorkScheduleList";
const equipmentUrl =
  urlStart + "api/APIWorkSchedule/GetEquipmentWithIdWorkSchedule";
const linkEquipmentUrl =
  urlStart + "api/APIWorkSchedule/LinkWorkScheduleToEquipments";
const unlinkEquipmentUrl =
  urlStart + "api/APIWorkSchedule/UnLinkWorkScheduleToEquipments";

const ShiftView = () => {
  const { loading, setLoading } = useContext(LoadingContext);
  const { response: workScheduleList, error } = useAxios({
    url,
    method: "get",
  });
  const [workSchedules, setWorkSchedules] = useState();
  const [currentIdWorkSchedule, setCurrentIdWorkSchedule] = useState();
  const [isAddingWorkSchedule, setIsAddingWorkSchedule] = useState();
  const [equipmentsList, setEquipmentsList] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const { setView } = useContext(ViewContext);
  const UpdateCurrentIdWorkSchedule = (e) => {
    setCurrentIdWorkSchedule(e.value);
  };

  const linkEquipment = () => {
    if (selectedEquipments.length > 0) {
      var array = [];
      selectedEquipments.map((e) => {
        var item = equipmentsList.find((i) => e.value == i.IdEquipment);
        item.IdWorkSchedule = currentIdWorkSchedule
        array.push(item);
      });
      axios
        .post(linkEquipmentUrl, [...array], {
          withCredentials: true,
          headers: { "content-type": "application/json" },
        })
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .post(unlinkEquipmentUrl, currentIdWorkSchedule, {
          withCredentials: true,
          headers: { "content-type": "application/json" },
        })
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (currentIdWorkSchedule && currentIdWorkSchedule != 0) {
      setLoading(true);
      axios
        .get(equipmentUrl, {
          withCredentials: true,
        })
        .then((res) => {
          setEquipmentsList(res.data);
          if (res) {
            var newArrayEquipments = [];
            res.data.map((e) => {
              newArrayEquipments.push({
                value: e.IdEquipment,
                label: e.EquipmentName,
              });
            });
            setEquipments(newArrayEquipments);
            var newArray = [];
            res.data
              .filter(
                (equipment) =>
                  equipment.IdWorkSchedule != 0 &&
                  equipment.IdWorkSchedule == currentIdWorkSchedule
              )
              .map((i) => {
                newArray.push({
                  value: i.IdEquipment,
                  label: i.EquipmentName,
                });
              });
            setSelectedEquipments(newArray);
          }
        })
        .catch((err) => {})
        .finally(() => setLoading(false));
    }
  }, [currentIdWorkSchedule]);

  useEffect(() => {
    if (workScheduleList !== null) {
      setWorkSchedules(workScheduleList);
      if (workScheduleList.length != 0) {
        setCurrentIdWorkSchedule(workScheduleList[0].IdWorkSchedule);
      }
    }
  }, [workScheduleList]);

  useEffect(() => {
    setView("ShiftView");
  }, []);

  useEffect(() => {
    if (isAddingWorkSchedule == false && !currentIdWorkSchedule) {
      setLoading(true);
      axios
        .get(url, { withCredentials: true })
        .then((res) => {
          setWorkSchedules(res.data);
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isAddingWorkSchedule]);

  return (
    <ViewContainer>
      {isAddingWorkSchedule && (
        <PopupAddWorkSchedule setIsAdding={setIsAddingWorkSchedule} />
      )}
      <div className="page-title">
        <div className="page-title-container">
          <div className="page-title-container-text">
            <RessourceEditor dataTranslate={"ShiftTitle"} />
          </div>
        </div>
      </div>
      <div className="page-header-filter">
        <div className="containerDiv">
          <RessourceEditor dataTranslate={"WorkScheduleSelection"} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex" }}>
              <SelectBox
                className="select-drop-down"
                items={workSchedules}
                displayExpr="Name"
                valueExpr="IdWorkSchedule"
                value={currentIdWorkSchedule}
                onValueChanged={UpdateCurrentIdWorkSchedule}
              />
              <FontAwesomeIcon
                className="addWorkSchedule"
                icon={"fas fa-square-plus"}
                onClick={() => setIsAddingWorkSchedule(true)}
              />
            </div>
            <div className="right-side">
              <div className="multi-select">
                <TableMultiSelectWithCheckBox
                  items={equipments}
                  selectedOptions={selectedEquipments}
                  setSelectedOptions={setSelectedEquipments}
                />
              </div>
              <button
                className="submit-equipment"
                onClick={() => linkEquipment()}
              >
                <RessourceEditor dataTranslate={"submitEquipment"} />
              </button>
            </div>
          </div>
        </div>
      </div>
      <ShiftContainer idWorkSchedule={currentIdWorkSchedule} />
    </ViewContainer>
  );
};

export default ShiftView;
