import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TopBarToggle = ({ icon, onClick }) => {
  return (
    <ul
      className="header-toggler nav-link none cursor"
      onClick={() => onClick((visibility) => !visibility)}
    >
      <FontAwesomeIcon icon={icon} className="icon icon-lg" />
    </ul>
  );
};

export { TopBarToggle };