import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Effectoa } from "../global/indexGlobal";
const urlStart = process.env.REACT_APP_URL


const MenuDropDownAdminItem = ({ title, icon }) => {
  const role = sessionStorage.getItem("Role");
  if (
    role == "Owner" ||
    role == "Administrator"
  ) {
    return (
      <Effectoa
        className="dropdown-item clickable d-flex align-items-center"
        caption={title}
        onClick={() => {
          window.open(urlStart + "Administration", "_blank");
        }}
      >
        <FontAwesomeIcon icon={icon} className="icon me-2" />
      </Effectoa>
    );
  } else {
    return <></>;
  }
};

export { MenuDropDownAdminItem };
