import axios from "axios";

const isFieldsValid = (startDate, endDate, type, detailEndDate) => {
  if (startDate == "" || endDate == "") {
    return false;
  }
  if (!HasDetail(type, detailEndDate)) {
    if (type == 0 && detailEndDate == "") return true;

    return false;
  }

  return true;
};

const HasDetail = (type, detailEndDate) => {
  if (type == 0 || detailEndDate == "") {
    return false;
  }
  return true;
};

const PostChanges = async (url, column) => {
  return axios.post(
    url,
    [
      {
        Title: column.Title,
        ID: column.ID,
        Index: column.Index,
        Items: [...column.Items],
      },
    ],
    {
      withCredentials: true,
      headers: { "content-type": "application/json" },
    }
  );
};

export { isFieldsValid, HasDetail, PostChanges };
