import React, { useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router';
import FormTemplate from "../global/form/FormTemplate";
import { LoadingContext } from '../contexts/LoadingContext';
import { ValidateFields } from "./LoginValidationHelper";
import fieldsJSON from './ForgotPasswordField.json';
import axios from 'axios';
const urlStart = process.env.REACT_APP_URL;
const url = urlStart + "api/APIUser/ForgotPassword/";

const ResetPasswordForm = () => {
    const [error, setError] = useState(null);
  const [requestError, setRequestError] = useState(null);
  const [elements, setElements] = useState(null);
  const { loading, setLoading } = useContext(LoadingContext);
  const nav = useNavigate();
  useEffect(() => {
    setElements(fieldsJSON[0]);
  }, []);

  const handleSubmit = (event) => {
    setLoading(true);
    setError(null);
    setRequestError(null);
    event.preventDefault();
    const error = ValidateFields(elements.fields);
    if (error.length == 0) {
      setError(null);
      axios
        .post(
          url,
          {
            username: elements.fields[0]["field_value"],
          },
          { withCredentials: true }
        )
        .then((res) => {
          if (res) {
            elements.fields[0]["field_value"] = "";
            nav("/login");
          }
        })
        .catch((err) => {
          setRequestError({
            statusCode: err.response.request.status,
            message: err.response.data.message,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setError(error);
      setLoading(false);
    }
  };
  const handleChange = (id, event) => {
    console.log(id + ":" + event.target.value)
    const newElements = { ...elements };
    newElements.fields.forEach((field) => {
      const { field_type, field_id } = field;
      if (id === field_id) {
        switch (field_type) {
          default:
            field["field_value"] = event.target.value;
            break;
        }
      }
      setElements(newElements);
    });
  };

  return (
    <>
      <FormTemplate
        className={"reset_password"}
        elements={elements}
        setElements={setElements}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        error={error}
      />
      {requestError && (
        <span className="text-danger d-block error">
          {requestError.message ?? "Erreur"}
        </span>
      )}
    </>
  );
}

export default ResetPasswordForm;
