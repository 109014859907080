import React, { useState, useEffect } from "react";
import { SideBarTitle } from "./index";
import { translate } from "./../languages/RessourcesManager";

const SideBarItemGroup = ({ children, caption, className, IsSortable }) => {
	const [isCollapsed, setCollapse] = useState(true);

	/*useEffect(() => {
	  if (isCollapsed == true) {
		translate();
	  }
	}, [isCollapsed]);*/

	return (
		<div className={IsSortable ? "branch-drag-handler" : ""}>
			<SideBarTitle
				setCollapse={setCollapse}
				isCollapsed={isCollapsed}
				className={className}
				caption={caption}
				IsSortable={IsSortable}
			/>
			{IsSortable ?  children :
				<span className={isCollapsed ? "" : "d-none"}>
					{children}
				</span>}


		</div>
	);
};

export default SideBarItemGroup;
