import React from "react";
import TableItem from "./TableItem";
import TableNewItemCard from "./TableNewItemCard";
import { RessourceEditor } from "../../languages/RessourcesEditor";

const TemplateColumn = ({ data, table, setRefresh, pauseTypes }) => {
  return (
    <>
      <div className="table-column table">
        <div className="table-header"><RessourceEditor dataTranslate={data.Title} /></div>
        <div className=" table-content">
            <TableNewItemCard
              indexParent={data.Index}
              indexItems={data.Items.length == 0 ? 0 : data.Items.length}
              table={table}
              setRefresh={setRefresh}
              pauseTypes={pauseTypes}
            />
          {data.Items &&
            data.Items.sort((a, b) => a.Index - b.Index).map((item, key) => (
              <TableItem
                key={key}
                data={item}
                indexParent={data.Index}
                table={table}
                setRefresh={setRefresh}
                pauseTypes={pauseTypes}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default TemplateColumn;
