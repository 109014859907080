import React from "react";
import { FormContext } from "../../contexts/FormContext";
import Field from "./Field";

import "./FormTemplate.css";

const FormTemplate = ({
	className,
	elements,
	setElements,
	handleSubmit,
	error,
	cancel
}) => {
	const { fields, title, title_className } = elements ?? {};

	const handleChange = (id, event) => {
		const newElements = { ...elements };
		newElements.fields.forEach((field) => {
			const { field_type, field_id } = field;
			if (id === field_id) {
				switch (field_type) {
					case "checkbox":
						field["field_value"] = event.target.checked;
						break;
					case "dropdown":
						field["field_value"] = event;
						break;
					default:
						field["field_value"] = event.target.value;
						break;
				}
			} else if (field_type === "groupcheckbox") {
				const option = field.field_options.find(
					(option) => option.field_id === id
				);
				if (option !== undefined) {
					if (option.all === true) {
						field.field_options.forEach((option) => {
							option["field_value"] = event.target.checked;
						});
					}
					option["field_value"] = event.target.checked;
					if (event.target.checked == false) {
						const option = (field.field_options.find(
							(option) => option["all"] == true
						)["field_value"] = false);
					}
				}
			}
			setElements(newElements);
		});
	};
	return (
		<FormContext.Provider
			value={{
				handleChange: handleChange,
				handleSubmit: handleSubmit,
				error: error,
			}}
		>
			<h1
				className={title_className}
			>
				{title}
			</h1>
			<form className={className}>
				{fields
					? fields.map((field, key) => <Field key={key} field={field} action={cancel} />)
					: null}
			</form>
		</FormContext.Provider>
	);
};

export default FormTemplate;
