import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormContext } from "../../contexts/FormContext";

const Submit = ({ field_id, field_value, className, icon, icon_classname, container_classname }) => {
  const { handleSubmit } = useContext(FormContext);
  return (
    <div className={container_classname}>
      <button
        id={field_id}
        type="submit"
        className={className}
        onClick={(e) => handleSubmit(e)}
      >
        <span>{field_value}</span>
        <FontAwesomeIcon className={icon_classname} icon={icon} />
      </button>
    </div>
  );
};

export default Submit;
