import shortid from "shortid";
import { Level1, Level2, Level3 } from "./ConstantsType";

// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed); // inserting task in new index

    return result;
};

export const remove = (arr, index) => [
    // part of the array before the specified index
    ...arr.splice(0, index),
    // part of the array after the specified index
    ...arr.slice(index + 1)
];

export const insert = (arr, index, newItem) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index)
];

export const reorderChildren = (children, splitDropZonePath, splitItemPath) => {
    if (splitDropZonePath.length === 1) {
        const dropZoneIndex = Number(splitDropZonePath[0]);
        const itemIndex = Number(splitItemPath[0]);
        return reorder(children, itemIndex, dropZoneIndex);
    }

    const updatedChildren = [...children];

    const curIndex = Number(splitDropZonePath.slice(0, 1));

    // Update the specific node's children
    const splitDropZoneChildrenPath = splitDropZonePath.slice(1);
    const splitItemChildrenPath = splitItemPath.slice(1);
    const nodeChildren = updatedChildren[curIndex];
    updatedChildren[curIndex] = {
        ...nodeChildren,
        lNodeChildrens: reorderChildren(
            nodeChildren.children,
            splitDropZoneChildrenPath,
            splitItemChildrenPath
        )
    };

    return updatedChildren;
};

export const removeChildFromChildren = (lNodeChildrens, splitItemPath) => {
    if (splitItemPath.length === 1) {
        const itemIndex = Number(splitItemPath[0]);
        return remove(lNodeChildrens, itemIndex);
    }

    const updatedChildren = [...lNodeChildrens];

    const curIndex = Number(splitItemPath.slice(0, 1));

    // Update the specific node's children
    const splitItemChildrenPath = splitItemPath.slice(1);
    const nodeChildren = updatedChildren[curIndex];
    updatedChildren[curIndex] = {
        ...nodeChildren,
        lNodeChildrens: removeChildFromChildren(
            nodeChildren.lNodeChildrens,
            splitItemChildrenPath
        )
    };

    return updatedChildren;
};

export const addChildToChildren = (lNodeChildrens, splitDropZonePath, item) => {
    if (splitDropZonePath.length === 1) {
        const dropZoneIndex = Number(splitDropZonePath[0]);
        return insert(lNodeChildrens, dropZoneIndex, item);
    }

    const updatedChildren = [...lNodeChildrens];

    const curIndex = Number(splitDropZonePath.slice(0, 1));

    // Update the specific node's children
    const splitItemChildrenPath = splitDropZonePath.slice(1);
    const nodeChildren = updatedChildren[curIndex];
    updatedChildren[curIndex] = {
        ...nodeChildren,
        lNodeChildrens: addChildToChildren(
            nodeChildren.lNodeChildrens,
            splitItemChildrenPath,
            item
        )
    };

    return updatedChildren;
};

export const handleMoveWithinParent = (
    layout,
    splitDropZonePath,
    splitItemPath
) => {
    return reorderChildren(layout, splitDropZonePath, splitItemPath);
};

export const handleAddColumDataToRow = layout => {
    const layoutCopy = [...layout];
    const Level2_STRUCTURE = {
        type: Level2,
        id: shortid.generate(),
        lNodeChildrens: []
    };

    return layoutCopy.map(row => {
        if (!row.lNodeChildrens.length) {
            row.lNodeChildrens = [Level2_STRUCTURE];
        }
        return row;
    });
};

export const handleMoveToDifferentParent = (
    layout,
    splitDropZonePath,
    splitItemPath,
    item
) => {
    let newLayoutStructure;
    const Level2_STRUCTURE = {
        type: Level2,
        id: item.id,
        name: item.name,
        icon: item.icon,
        path: item.path,
        menuInformationsID: item.menuInformationsID,
        lNodeChildrens: item.lNodeChildrens
    };

    const Level1_STRUCTURE = {
        type: Level1,
        id: item.id,
        name: item.name,
        icon: item.icon,
        path: item.path,
        menuInformationsID: item.menuInformationsID,
        lNodeChildrens: item.lNodeChildrens
    };

    switch (splitDropZonePath.length) {
        case 1: {
            // moving column outside into new row made on the fly
            if (item.type === Level2) {
                newLayoutStructure = {
                    ...Level1_STRUCTURE
                    //lNodeChildrens: item.lNodeChildrens
                };
            } else {
                // moving component outside into new row made on the fly
                newLayoutStructure = {
                    ...Level1_STRUCTURE
                    //lNodeChildrens: [Level2_STRUCTURE]
                };
            }
            break;
        }
        case 2: {
            // moving component outside into a row which creates column
            if (item.type === Level3) {
                newLayoutStructure = Level2_STRUCTURE;
            } else {
                // moving column into existing row
                newLayoutStructure = item;
            }

            break;
        }
        default: {
            newLayoutStructure = item;
        }
    }

    let updatedLayout = layout;
    updatedLayout = removeChildFromChildren(updatedLayout, splitItemPath);
    updatedLayout = handleAddColumDataToRow(updatedLayout);
    updatedLayout = addChildToChildren(
        updatedLayout,
        splitDropZonePath,
        newLayoutStructure
    );

    return updatedLayout;
};

export const handleMoveSelectableChildrenIntoParent = (
    layout,
    splitDropZonePath,
    item
) => {
    let newLayoutStructure;
    switch (splitDropZonePath.length) {
        case 1: {
            newLayoutStructure = {
                type: Level1,
                id: item.id,
                name: item.name,
                icon: item.icon,
                path: item.path,
                menuInformationsID: item.menuInformationsID,
                lNodeChildrens: []
            };
            break;
        }
        case 2: {
            newLayoutStructure = {
                type: Level2,
                id: item.id,
                name: item.name,
                icon: item.icon,
                path: item.path,
                menuInformationsID: item.menuInformationsID,
                lNodeChildrens: []
            };
            break;
        }
        default: {
            newLayoutStructure = item;
        }
    }

    return addChildToChildren(layout, splitDropZonePath, newLayoutStructure);
};

export const handleRemoveItemFromLayout = (layout, splitItemPath) => {
    return removeChildFromChildren(layout, splitItemPath);
};


