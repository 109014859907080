import React from 'react';
import { MenuDropDownItem } from './index';

const MenuDropDownItemList = ({ items }) => {
    return (
        <>
            {items.map((i, key) => {
                return (
					<MenuDropDownItem
						key={key}
                        title={i.title}
                        icon={i.icon}
                        menuType={i.menuType}
					/>
				);
            })}
        </>
    );
}

export { MenuDropDownItemList };
