import React from 'react'
import EffectoInput from "../../global/EffectoInput";

const MenuViewerHeader = ({ menuActive, handleChange, menuInformations, IsDefault, setIsDefaultMenu, menuInformationID   }) => {

	return ( <>
		<EffectoInput
			id="client"
			type="select"
			value={menuActive}
			change={handleChange}
			label="Menu"
			valueName="id"
			displayName="name"
			table={menuInformations}
			className="select-drop-down"
		/>

		<div className="width-50">
			<span className="ml-2">Default</span>
			<input id={menuInformationID} type='checkbox'
				className="d-inline-flex width-50"
					checked={IsDefault}
					onChange={() => setIsDefaultMenu(!IsDefault)}
				//onChange={() => { if (menuActive == true) return true; else return false; }}

				/>

		</div>
		</>)

}
export default MenuViewerHeader;