import React from 'react';
import { RessourceEditor } from '../languages/RessourcesEditor';


const DatabaseTitle = ({ title }) => {
    return (
            <div className="datagrid-title-container">
                <div className='datagrid-title-div'>
                    <div className='datagrid-title'><RessourceEditor dataTranslate={title}/></div>
                </div>
            </div>
    );
}

export default DatabaseTitle;
