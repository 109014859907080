import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RessourceEditor } from "../../languages/RessourcesEditor";

const TableDeleteContent = ({ CancelAction, ConfirmAction }) => {
  return (
    <>
      <div className="table-info-msg"><RessourceEditor dataTranslate={"deleteMsg"}/></div>
      <div className="confirm-delete ">
        <FontAwesomeIcon
          className="cancel-delete-icon"
          icon="fa-solid fa-xmark"
          onClick={(e) => {
            CancelAction();
          }}
        />
        <FontAwesomeIcon
          className="confirm-delete-icon"
          icon="fa-solid fa-check"
          onClick={(e) => {
            ConfirmAction();
          }}
        />
      </div>
    </>
  );
};

export default TableDeleteContent;
