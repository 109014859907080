import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Button = ({ field_id, field_value, className, icon, icon_classname, container_classname, action }) => {

	return (
		<div className={container_classname}>
			<button
				id={field_id}
				type="button"
				className={className}
				onClick={(e) => action(false)} >

				<span>{field_value}</span>
				<FontAwesomeIcon className={icon_classname}
					icon={icon} />

			</button>

		</div>
	);
};

export default Button;