import React from "react";
import "./Downtime.css"

const DowntimeContainer = ({ children }) => {


    return (
        <div className="downtimeContainer">

            {children}
        </div>
    );
}

export default DowntimeContainer;

