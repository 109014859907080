import React, { useContext } from "react";
import { SaveRessources } from "../languages/RessourcesManager";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Effectoa } from "../global/indexGlobal";
import { RessourcesContext } from '../contexts/RessourcesContext';
const url = process.env.REACT_APP_URL;
const MenuDropDownSaveResItem = ({ title, icon }) => {
  const { ressources, lang } = useContext(RessourcesContext);
  return (
    <Effectoa
      className="dropdown-item clickable d-flex align-items-center"
      onClick={() => SaveRessources(ressources, url, lang)}
      caption={title}
      clickOverride={true}
    >
      <FontAwesomeIcon icon={icon} className="icon me-2" />
    </Effectoa>
  );
};

export { MenuDropDownSaveResItem };
