import React from "react";
import { SideBarItemGroup, SideBarItemList } from "./index";

const SideBarItemGroupList = ({ data, IsSortable }) => {

	return (
		<>
			{ data.map((data, key) => {
				return (
					<SideBarItemGroup caption={data.caption} key={key} IsSortable={IsSortable}>
						<SideBarItemList items={data.lNodeChildrens} IsSortable={IsSortable} />
					</SideBarItemGroup>
				);
			})}
		</>
	);
};

export default SideBarItemGroupList;
