import React, { useState, useContext } from "react";
import TableItemDetailContent from "./TableItemDetailContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingContext } from "../../contexts/LoadingContext";
import { States } from "./Constants";
import axios from "axios";
import TableEditItem from "./TableEditItem";
const urlStart = process.env.REACT_APP_URL;
const url = urlStart + "api/APIWorkSchedule/PostWorkSchedulePeriods";

const TableItemContent = ({
  data,
  table,
  setRefresh,
  indexParent,
  pauseTypes,
}) => {
  const { loading, setLoading } = useContext(LoadingContext);
  const [InEdition, setInEdition] = useState(false);
  const [error, setError] = useState();

  const MovePeriod = async (item) => {
    setLoading(true);
    var column = table[data.ParentIndex];
    item.State = States.IsModified;
    await axios
      .post(
        url,
        [
          {
            Title: column.Title,
            ID: column.ID,
            Index: column.Index,
            Items: [item],
          },
        ],
        {
          withCredentials: true,
          headers: { "content-type": "application/json" },
        }
      )
      .then(() => {
        setRefresh(true);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const ToggleEditionForm = (e) => {
    setInEdition((prev) => !prev);
  };

  const IndexUp = (e) => {
    var index = data.Index;
    if (index != 0) {
      var itemToMoveUp = null;
      var itemToMoveDown = null;
      for (var i = 0; i < table[indexParent].Items.length; i++) {
        if (i == index - 1) {
          table[indexParent].Items[i].Index = i + 1;
          itemToMoveDown = table[indexParent].Items[i];
        }

        if (i == index) {
          table[indexParent].Items[i].Index = i - 1;
          itemToMoveUp = table[indexParent].Items[i];
        }
      }
      if (itemToMoveUp != null && itemToMoveDown != null) {
        MovePeriod(itemToMoveUp);
        MovePeriod(itemToMoveDown);
      }
      setRefresh(true);
    }
  };

  if (InEdition) {
    return (
      <TableEditItem
        table={table}
        data={data}
        setInEdition={setInEdition}
        state={States.IsModified}
        setRefresh={setRefresh}
        Title={"ModifyCardTitle"}
        TitleCss={"color-edition"}
        pauseTypes={pauseTypes}
      />
    );
  }

  return (
    <>
      <div className="table-content-card-top">
        <div>
          <div className="table-content-icon pb-1">
            {data && data.Index != 0 && (
              <FontAwesomeIcon
                className="edit-icon"
                icon={"fa-sharp fa-solid fa-arrow-up"}
                onClick={IndexUp}
              />
            )}
          </div>
        </div>
        <div>
          {data.StartDate.slice(0, 5)} - {data.EndDate.slice(0, 5)}
        </div>
        <div className="table-content-icon">
          <FontAwesomeIcon
            className="edit-icon"
            icon={"fa-regular fa-pencil"}
            onClick={ToggleEditionForm}
          />
        </div>
      </div>
      {data.Details && (
        <div className="table-content-card-bottom">
          {data.Details.map((detail, key) => (
            <TableItemDetailContent key={key} data={detail} />
          ))}
        </div>
      )}
    </>
  );
};

export default TableItemContent;
