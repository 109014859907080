import React, { useContext } from "react";
import { RessourcesContext } from "../contexts/RessourcesContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Effectoa } from "../global/indexGlobal";

const MenuDropDownEditResItem = ({ title, icon }) => {
  const { setEditingRessources, isRessourcesEditing } =
    useContext(RessourcesContext);
  return (
    <Effectoa
      className="dropdown-item clickable d-flex align-items-center"
      onClick={() => {
        setEditingRessources(!isRessourcesEditing);
      }}
      caption={title}
      clickOverride={true}
    >
      <FontAwesomeIcon icon={icon} className="icon me-2" />
    </Effectoa>
  );
};

export { MenuDropDownEditResItem };
