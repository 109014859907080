import React from "react";
import "./custom.css";
import Library from "./FontAwesome";
import "bootstrap/dist/css/bootstrap.min.css";
import { ViewContextProvider } from "./components/contexts/ViewContext";
import { DashboardContextProvider } from "./components/contexts/DashboardContext";
import { RessourcesContextProvider } from "./components/contexts/RessourcesContext";
import RouteManager from "./RouteManager";

const App = () => {
  return (
    <DashboardContextProvider>
      <ViewContextProvider>
        <RessourcesContextProvider>
          <RouteManager />
        </RessourcesContextProvider>
      </ViewContextProvider>
    </DashboardContextProvider>
  );
};

export default App;
