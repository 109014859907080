import React from "react";
import classNames from "classnames";
import { useDrop } from "react-dnd";
import { Level3, SELECTABLE_ITEM, Level1, Level2 } from "./ConstantsType";

const ACCEPTS = [SELECTABLE_ITEM, Level3, Level1, Level2, "node"];

const DropZone = ({ data, onDrop, isLast, className, name, menu }) => {
	//UPGRADE PASSING accept in properties to block lvl diff 
	const [{ isOver, canDrop }, drop] = useDrop({
		accept: ACCEPTS,
		drop: (item, monitor) => {
			//console.log(item);
			onDrop(data, item);
		},
		canDrop: (item, monitor) => {
			if (menu != undefined) {
				//const dropZonePath = data.path;
				//const splitDropZonePath = dropZonePath.split("-");
				//const itemPath = item.path;
				//console.log("path : " + splitDropZonePath);
				//if (item.type == SELECTABLE_ITEM)
				return true;

				//for (let i = 0; i < size; i++) {
				//	if (menu[i].id == item.id)
				//		return false;
				//	else {
				//		if (menu[i].lNodeChildrens != undefined || menu[i].lNodeChildrens != [])
				//			for (let j = 0; i < menu[i].lNodeChildrens.length; i++) {
				//				if (menu[i].lNodeChildrens[j].id == item.id)
				//					return false;
				//			}
				//	}
				//}

				//if (item.type == 'selectableItem') {
				//	console.log("ok");
				//}
				//console.log(item.type == 'selectableItem');
				//console.log(itemPath);
				// sidebar items can always be dropped anywhere
				if (!itemPath) {
					// if (data.childrenCount >= 3) {
					//  return false;
					// }

					return true;
				}



				//const splitItemPath = itemPath.split("-");

				//// limit columns when dragging from one row to another row
				//const dropZonePathLevel1Index = splitDropZonePath[0];
				//const itemPathRowIndex = splitItemPath[0];
				//const diffRow = dropZonePathLevel1Index !== itemPathRowIndex;
				//if (
				//	diffRow &&
				//	splitDropZonePath.length === 2 &&
				//	data.childrenCount >= 3
				//) {
				//	return false;
				//}
				//// Invalid (Can't drop a parent element (row) into a child (column))
				//const parentDropInChild = splitItemPath.length < splitDropZonePath.length;
				//if (parentDropInChild) return false;
				//// Current item can't possible move to it's own location
				//if (itemPath === dropZonePath) return false;

				//// Current area
				//if (splitItemPath.length === splitDropZonePath.length) {
				//	const pathToItem = splitItemPath.slice(0, -1).join("-");
				//	const currentItemIndex = Number(splitItemPath.slice(-1)[0]);

				//	const pathToDropZone = splitDropZonePath.slice(0, -1).join("-");
				//	const currentDropZoneIndex = Number(splitDropZonePath.slice(-1)[0]);

				//	if (pathToItem === pathToDropZone) {
				//		const nextDropZoneIndex = currentItemIndex + 1;
				//		if (nextDropZoneIndex === currentDropZoneIndex) return false;
				//	}
				//}

				return true;
			}
			//return false;
			return true;
		},
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		})
	});

	const isActive = isOver /*&& canDrop*/;
	return (
		<div
			className={classNames(
				"dropZone",
				{ active: isActive, isLast },
				className
			)}
			ref={drop}
		>
			{name}
		</div>
	);
};
export default DropZone;
