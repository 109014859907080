import React from "react";
import { Effectoa } from '../global/indexGlobal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SideBarTitle = ({ caption, setCollapse, isCollapsed, IsSortable }) => {
    return (
        <li className={IsSortable ? "tree-branch branch-level-1" : "nav-title"}>
            <Effectoa onClick={() => setCollapse((Collapse) => !Collapse)} className="d-flex title-link cursor" caption={caption} isRight="true" >
                  <FontAwesomeIcon icon=
                  {isCollapsed ?
                          "fa-solid fa-angle-up"
                          :
                          "fa-solid fa-angle-down"
                      }
                      className="nav-icon pos-right txt-black" />               
          </Effectoa>
    </li>
  );
};

export default SideBarTitle;
