import axios from "axios";

const isKey = (columnName, foreignKeys) => {
  var bool = false;
  if (
    foreignKeys != undefined &&
    foreignKeys != null &&
    foreignKeys.length != 0
  ) {
    foreignKeys.forEach((key) => {
      if (columnName === key.FKIdColumnName) {
        bool = true;
      }
    });
  }
  return bool;
};
const IsValidKey = (columnName, foreignKeys) => {
  var bool = false;
  foreignKeys.forEach((key) => {
    if (
      columnName === key.FKIdColumnName &&
      key.FKValueColumnName != "NO VALUE"
    ) {
      bool = true;
    }
  });
  return bool;
};

const processBatchUpdate = async (
  url,
  changes,
  component,
  tableName,
  idName
) => {
  await sendBatchRequest(url, changes, tableName, idName, component);
  component.cancelEditData();
};

const sendBatchRequest = async (url, changes, tableName, idName, component) => {
  if (tableName != "") {
    const data = [];
    changes.forEach((change) => {
      if (change.data != undefined) {
        const content = [];
        const propertyName = Object.keys(change.data);
        propertyName.forEach((property) => {
          if (property != "__KEY__") {
            content.push({
              propertyName: property,
              value: String(change.data[property]),
            });
          }
        });
        data.push({
          id: change.key[idName] ? change.key[idName] : change.key,
          data: content,
          type: change.type,
          tableName: tableName.Name ? tableName.Name: tableName,
          idName: idName,
        });
      } else {
        data.push({
          id: change.key[idName] ? change.key[idName] : change.key,
          type: change.type,
          tableName: tableName.Name ? tableName.Name : tableName,
          idName: idName,
        });
      }
    });
    await axios
      .post(url, data, {
        withCredentials: true,
        headers: { "content-type": "application/json" },
      })
      .catch((error) => {
        component.cancelEditData();
        throw error;
      });
  }
};

const setupColumn = (columns, foreignKeys, foreignData) => {
  if (columns) {
    var tempColumns = [];
    columns.map((i) => {
      if (!isKey(i.Column_Name, foreignKeys)) {
        if (i.Ordinal_Position === 1) {
          tempColumns.push({
            dataField: i.Column_Name,
            showInColumnChooser: false,
          });
        } else {
          tempColumns.push({ dataField: i.Column_Name });
        }
      } else {
        if (!IsValidKey(i.Column_Name, foreignKeys)) {
          tempColumns.push({
            dataField: i.Column_Name,
            allowEditing: false,
          });
        } else {
          var i = foreignKeys.findIndex(
            (key) => key.FKIdColumnName == i.Column_Name
          );
          if (i !== -1) {
            if (foreignData && foreignKeys) {
              if (foreignData[i] != undefined && foreignData[i] != null) {
                if (foreignKeys[i].FKValueColumnName != "NO VALUE") {
                  tempColumns.push({
                    dataField: foreignKeys[i].FKIdColumnName,
                    caption: foreignKeys[i].FKIdColumnName,
                    lookup: {
                      dataSource: foreignData[i].Rows,
                      valueExpr: foreignKeys[i].PKColumnName,
                      displayExpr: foreignKeys[i].FKValueColumnName,
                      allowClearing: true,
                    },
                  });
                }
              }
             }
            //tempColumns = allowTraductionOnTable(tempColumns);
          }
        }
      }
    });
    return tempColumns;
  }
};

//const allowTraductionOnTable = (columns) => {
//    if (columns) {
        
//    }
//    return columns
//}

async function loadTableData(url, tableName, condition) {
  if (tableName != "") {
    var result = await axios.post(
      url,
      JSON.stringify({
        tableName: tableName,
        condition: condition,
      }),
      {
        withCredentials: true,
        headers: { "content-type": "application/json" },
      }
    );
    return result;
  }
}
async function GetSpecificTableData(url) {
  if (url != "") {
    var result = await axios.get(url, {
      withCredentials: true,
      headers: { "content-type": "application/json" },
    });
    return result;
  }
}
async function PostSpecificTableData(url, tableName, condition) {
  if (tableName != "") {
    var result = await axios.post(
      url,
      JSON.stringify({
        tableName: tableName,
        condition: condition,
      }),
      {
        withCredentials: true,
        headers: { "content-type": "application/json" },
      }
    );
    return result;
  }
}
async function loadColumns(url, tableName) {
  if (tableName != "") {
    var result = await axios.get(url + "/" + tableName, {
      withCredentials: true,
    });
    return result;
  }
}
async function loadForeignKeys(url, tableName) {
  if (tableName != "") {
    var result = await axios.get(url + "/" + tableName, {
      withCredentials: true,
    });
    return result;
  }
}
async function loadForeignData(url, tableName) {
  if (tableName != "") {
    var result = await axios.get(url + "/" + tableName.replace("dbo.", ""), {
      withCredentials: true,
    });
    return result;
  }
}

export {
    isKey,
    IsValidKey,
    processBatchUpdate,
    setupColumn,
    loadTableData,
    loadColumns,
    loadForeignKeys,
    loadForeignData,
    GetSpecificTableData,
    PostSpecificTableData,
};
