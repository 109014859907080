import React from 'react';
import { DdlLanguage } from '../languages/DdlLanguage';

const MenuDropDownDropLang = () => {
    return (
        <DdlLanguage 
        className="dropdown-item"
        />
    );
}

export { MenuDropDownDropLang };
