import { useState, useEffect } from 'react';
import axios from 'axios';


const useAxios = ({ url, method, body = null}) => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState('');
    const [loading, setloading] = useState(true);

    const fetchData = () => {
        axios
            [method](url, { withCredentials: true }, JSON.parse(body))
            .then((res) => {
                setResponse(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setloading(false);
            });

        return () => {
            const controller = new AbortController(); controller.abort();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return { response, error, loading };
};

export default useAxios;