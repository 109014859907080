import React from "react";
import { SelectBox } from "devextreme-react/select-box";

const DatabaseTableDdl = ({ items, setTableName, setHasChanged }) => {
  items.map((item) => {
    item.ID = item.Name + (item.Condition ? "," + item.Condition : "")
  })
  const updateTableName = (value) => {
    var table = items.find(element => element.ID == value);
    setTableName(table);
    setHasChanged(true);
  };

  return (
      <SelectBox
        items={items}
        valueExpr="ID"
        displayExpr="DisplayName"
        onValueChange={(value) => updateTableName(value)}
      />
  );
};

export default DatabaseTableDdl;
