import { useState, createContext } from 'react';
const LoadingContext = createContext({
    loading: false,
	setLoading: (loading) => { },
})

const LoadingContextProvider = ({ children }) => {
	const [loading, setLoading] = useState(false);

	return <LoadingContext.Provider value={{ loading: loading, setLoading:setLoading }} >
				{children}
			</LoadingContext.Provider>
}

export { LoadingContext, LoadingContextProvider }