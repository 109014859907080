import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import FormTemplate from "../global/form/FormTemplate";
import fieldsJSON from "./UserFields.json";
import axios from "axios";
import { LoadingContext } from "../contexts/LoadingContext";

const urlStart = process.env.REACT_APP_URL;
const urlGet = urlStart + "api/APIUser/GetUser/";
const url = urlStart + "api/APIUser/GetUser/";

async function GetUserData() {
    var result = await axios.get(
        urlGet,
        {
            withCredentials: true,
            headers: { "content-type": "application/json" },
        }
    );
    return result;
}



const UserForm = ({userInformations}) => {
    const [error, setError] = useState(null);
    const [requestError, setRequestError] = useState(null);
    const [elements, setElements] = useState(null);
    const { loading, setLoading } = useContext(LoadingContext);
    //const [userInformations, setuserInformations] = useState("");
    const nav = useNavigate();

    useEffect(() => {
        setElements(fieldsJSON[0]);
    }, []);

 //   var loadUserData = await GetUserData();

 //   if (loadUserData) {
 //       setuserInformations(loadUserData.data);
	//}

    const handleSubmit = (event) => {
        setLoading(true);
        setError(null);
        setRequestError(null);
        event.preventDefault();
        const error = ValidateFields(elements.fields);
        if (error.length == 0) {
            setError(null);
            axios
                .post(
                    url,
                    {
                        email: elements.fields[0]["field_value"],
                        password: elements.fields[1]["field_value"],
                    },
                    { withCredentials: true }
                )
                .then((res) => {
                    if (res) {
                        elements.fields[0]["field_value"] = "";
                        elements.fields[1]["field_value"] = "";
                        nav("/");
                    }
                })
                .catch((err) => {
                    setRequestError({
                        statusCode: err.response.request.status,
                        message: err.response.data.message,
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setError(error);
            setLoading(false);
        }
    };
    const handleChange = (id, event) => {
        const newElements = { ...elements };
        newElements.fields.forEach((field) => {
            const { field_type, field_id } = field;
            if (id === field_id) {
                switch (field_type) {
                    case "checkbox":
                        field["field_value"] = event.target.checked;
                        break;

                    default:
                        field["field_value"] = event.target.value;
                        break;
                }
            }
            setElements(newElements);
        });
    };

    return (
        <>
            <FormTemplate
                className={"login"}
                elements={elements}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                error={error}
            />
            {requestError && (
                <span className="text-danger d-block error">
                    {requestError.message ?? "Erreur"}
                </span>
            )}
        </>
    );
};

export default UserForm;
