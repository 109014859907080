import React  from "react";
import LoginContainer from "../components/login/LoginContainer";
import LoginDecoration from "../components/login/LoginDecoration";
import LoginForm from "../components/login/LoginForm";
import useAuth from "../components/hooks/useAuth";
import { Navigate } from "react-router";
import LoadingHandler from "../components/global/LoadingHandler";

const LoginView = () => {
    const { loading, loggedIn, error } = useAuth();

  if (loading) {
    return <></>;
  }
  if (error && error.response.status != 401 && error.status != 401) {
    return <Navigate to="Error" />;
  } 
  
  if (loggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <div className="containerPrincipal">
      <div className="content">
        <div className="info">
          <main role="main" className="mainContainer pl-3 pr-3">
            <LoadingHandler>
              <div className="row overflow-hidden">
                <div className="text-center login-left d-none col-xl-9 d-xl-block">
                  <LoginDecoration />
                </div>
                <div
                  className=" col-lg-10 offset-lg-1 col-xl-3 offset-xl-0"
                  style={{ backgroundColor: "white !important" }}
                >
                  <LoginContainer>
                    <LoginForm></LoginForm>
                  </LoginContainer>
                </div>
              </div>
            </LoadingHandler>
          </main>
        </div>
      </div>
    </div>
  );
};

export default LoginView;
