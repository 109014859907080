import React, { useState, useContext, useEffect } from 'react'
import ViewContainer from "../components/container/ViewContainer"
import UserContainer from "../components/user/UserContainer"
import "../Css/FormInput.css"
import { ViewContext } from "../components/contexts/ViewContext";




const UserView = () => {
	const { setView } = useContext(ViewContext);

	useEffect(() => {
		setView("User");
	}, [])

	return (
		<ViewContainer>
			<div className="page-title">
				<div className="page-title-container">
					<div className="page-title-container-before">
					</div>
					<div className="page-title-container-text">
						Votre espace
					</div>
				</div>
			</div>
				<UserContainer />
		</ViewContainer> 
		);
}

export default UserView;