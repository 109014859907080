import { useState } from 'react';
import Select from 'react-select';

function GetText(row, optionTextFormat, optionText) {
    var textReturn = optionTextFormat;
    if (optionText && optionTextFormat) {
        optionText.forEach((text) => {
            textReturn = textReturn.replace(text, row[text]);
        });
    }
    return textReturn;
}

const EffectoInput = ({ id, type, value, change, label, disabled, table, size, className, optionValue, optionText, optionTextFormat, valueName, displayName }) => {   

    if (type == "textarea") {
        return (
            <>
                <div className="containerDiv">
                    <label htmlFor={id}>{label}</label>
                    <textarea id={id} className={className} value={value} onChange={(e) => change(e.target.value)} />
                </div>
            </>           
        )
    }
    if (type == "select") {
        var array = [];
        if (table) {
            table.map((item, key) => array.push({ value: valueName ? item[valueName] : key, label: displayName ? item[displayName] : item }))      
        }       

        const customCSS = {
            dropdownIndicator: () => ({
                padding: 0,
            }),
            indicatorContainer: () => ({
                padding: 0,
                width: 0,
            }),
        }

        return (
            <div className="containerDiv">
                <label htmlFor={id}>{label}</label>
                <Select id="{id}" styles={customCSS} className={className} isDisabled={disabled} isClearable="true" value={value ? value.label ? array.filter(obj => obj.label === value.label) : value.value ? array.filter(obj => obj.value === value.value) : array.filter(obj => obj.value === value) : value} size={size} onChange={(e) => { change(e) }} options={array}>
                    {//<option hidden value="">Select an option</option>
                    //<option value="empty">UNSELECT</option>
                    //{table && table.map((row, key) => { return (<option key={key} value={optionValue ? row[optionValue] : row}>{optionText ? GetText(row, optionTextFormat, optionText) : row} </option>) })}
                    }
                </Select>
            </div>
        )        
    }
    else {
        return (
            <div className="containerDiv">
                <label htmlFor={id}>{label}</label>
                <input id={id} type={type} value={value}  onChange={(e) => change(e.target.value)} disabled={disabled} />
            </div>
        )
    }
}

export default EffectoInput