import React, { useState, useEffect, useContext } from "react";
import TableTemplate from "../global/table/TableTemplate";
import { Navigate } from "react-router";
import { LoadingContext } from "../contexts/LoadingContext";
import axios from "axios";
const urlStart = process.env.REACT_APP_URL;
const url = urlStart + "api/APIWorkSchedule/GetWorkSchedulesForDisplay";

const ShiftContainer = ({ idWorkSchedule }) => {
  const { loading, setLoading } = useContext(LoadingContext);
  const [error, setError] = useState();
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (idWorkSchedule && idWorkSchedule != 0) {
      LoadData(idWorkSchedule);
    }
  }, [idWorkSchedule]);

  const LoadData = async (idWorkSchedule) => {
    setLoading(true);
    await axios
      .get(url + "/" + idWorkSchedule, {
        withCredentials: true,
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => setError(err))
      .finally(() => {
        setLoading(false);
      });
  };

  if (error) {
    return <Navigate to={"/Error"} />;
  }

  return (
    <div className="page-container">
      <div className="page-card-container width-100">
        {idWorkSchedule && idWorkSchedule != 0 && (
          <TableTemplate data={data} setRefresh={setRefresh} />
        )}
      </div>
    </div>
  );
};

export default ShiftContainer;
