import React from "react";
import "./Downtime.css"
import DowntimeForm from "./DowntimeForm"



const DowntimeInsertForm = ({ setPopupMessage, togglePopup, setPopupTitle, setPopupMessageType, setDowntimeInserted }) => {
    

    return (
        <DowntimeForm setPopupMessage={setPopupMessage} togglePopup={togglePopup} setPopupTitle={setPopupTitle} setPopupMessageType={setPopupMessageType} setDowntimeInserted={setDowntimeInserted} formType="insert" />
    )
}

export default DowntimeInsertForm;