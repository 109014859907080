import React, { useState } from "react";
import { TopBarItemList } from "./index";
import { TopBarToggle } from "./index";
import {icons} from './index';

const AppTopNavBar = ({ OnSidebarVisibility }) => {
  const [selectedDropDown, setSelectedDropDown] = useState("");

  return (
    <div className="header header-sticky">
      <div className="container-fluid">
        <TopBarToggle onClick={OnSidebarVisibility} icon={"fa-solid fa-bars"} />
        <ul className="header-nav ms-auto">
          <TopBarItemList
            items={icons}
            selectedDropDown={selectedDropDown}
            setSelectedDropDown={setSelectedDropDown}
          />
        </ul>
      </div>
    </div>
  );
};

export { AppTopNavBar };
