import React from "react";
import { RessourceEditor } from "../../languages/RessourcesEditor";

const TableSelectPauseType = ({ pauseTypes, pauseType, label, onChange }) => {
  return (
    <>
      <div className="table-input-label"><RessourceEditor dataTranslate={label} /></div>
      <select className="width-65" value={pauseType} onChange={onChange}>
        {pauseTypes &&
          pauseTypes.map((type, key) => {
            return (
              <option key={key} value={key}>
                {type/*<RessourceEditor dataTranslate={type} />*/}
              </option>
            );
          })}
      </select>
    </>
  );
};

export default TableSelectPauseType;
