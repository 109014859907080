import React, { useContext } from "react";
import { RessourceEditor } from "../languages/indexLang";
import { RessourcesContext } from "../contexts/RessourcesContext";

const Effectoa = ({
  caption,
  onClick,
  className,
  value,
  isRight,
  children,
  clickOverride,
}) => {
  const { isRessourcesEditing } = useContext(RessourcesContext);

  return (
    <a
          className={className + " cursor"}
      onClick={(e) => {
        if (isRessourcesEditing && !clickOverride) () => {};
        else onClick(e);
      }}
      value={value}
    >
      {isRight ? (
        <>
          <RessourceEditor dataTranslate={caption} />
          {children}
        </>
      ) : (
        <>
          {children}
          <RessourceEditor dataTranslate={caption} />
        </>
      )}
    </a>
  );
  //}

  /* return (
    <a className={className} onClick={onClick} value={value}>
      {isRight ? (
        <>
          <RessourceEditor dataTranslate={caption} />
          {children}
        </>
      ) : (
        <>
          {children}
          <RessourceEditor dataTranslate={caption} />
        </>
      )}
    </a>
  );*/
};

export default Effectoa;
