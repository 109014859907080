import React from "react";
import TableItemContent from "./TableItemContent";

const TableItem = ({
  data,
  indexParent,
  table,
  setRefresh,
  pauseTypes,
}) => {
  return (
    <>
      <div className="table-card">
        <TableItemContent
          data={data}
          table={table}
          setRefresh={setRefresh}
          indexParent={indexParent}
          pauseTypes={pauseTypes}
        />
      </div>
    </>
  );
};

export default TableItem;
