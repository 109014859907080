import React, { useState, useEffect } from "react";
import "./GlobalPopup.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Popup = ({ Message, handleClose, Title, MessageType }) => {
  const [textColor, setTextColor] = useState("");

  function GetTextColor() {
    switch (MessageType) {
      case "Success":
        return "text-success";
      case "Error":
        return "text-danger";
      default:
    }
  }

  useEffect(() => {
    setTextColor(GetTextColor());
  }, [Message]);

  return (
    <div className="popup">
      <div className="popup-icone">
        {MessageType == "Error" && (
          <FontAwesomeIcon icon="fa-solid fa-bug" className="mt-2" />
        )}
        {MessageType == "Success" && (
          <FontAwesomeIcon icon="fa-solid fa-check" className="mt-2" />
        )}
      </div>
      <div className={"box " + textColor}>
        <span className="box-title">{Title + " : "}</span>
        <span className="box-message"> {Message} </span>
      </div>

      <div className="box-option-content">
        {/*<div className="box-option mail js-option">
          <FontAwesomeIcon
            icon="fa-duotone fa-paper-plane "
            className="mt-1"
          ></FontAwesomeIcon>
		</div>*/}
        <div className="box-option delete js-option">
          <FontAwesomeIcon
            icon="fas fa-trash"
            className="mt-1"
            onClick={handleClose}
          ></FontAwesomeIcon>
        </div>
      </div>
    </div>
  );
};

export default Popup;
