import React, { useRef } from "react";
import { useDrag } from "react-dnd";
import { Level2 } from "./ConstantsType";
import DropZone from "./DropZone";
import Level3Node from "./Level3Node";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Level2Node = ({ data, nodes, handleDrop, path, menu }) => {
	const ref = useRef(null);

	const [{ isDragging }, drag] = useDrag({
		item: {
			type: Level2,
			id: data.id,
			path,
			icon: data.icon,
			name: data.name,
			menuInformationsID: data.menuInformationsID,
			lNodeChildrens: data.lNodeChildrens,
			dType: data.dType,
			},
		type: "selectableItem",
		collect: (monitor) => ({
			isDragging: monitor.isDragging()
		})
	});

	const opacity = isDragging ? 0 : 1;
	drag(ref);

	const renderLevel3 = (level3, currentPath) => {
		return (
			<Level3Node
				key={level3.id}
				data={level3}
				nodes={nodes}
				path={currentPath}
				menu={menu}
			/>
		);
	};

	return (
		<div
			ref={ref}
			style={{ opacity }}
			className="base draggable column ml-2"
		>
			<div className="draggableItem">
				{data.icon &&
					<FontAwesomeIcon icon={data.icon} className="icon icon-lg" />
				}
				<span className="ml-1">
					{data.name}
				</span>
			</div>

			{data.lNodeChildrens.length > 0 ?
				data.lNodeChildrens.map((level3, index) => {
					const currentPath = `${path}-${index}`;

					return (
						<React.Fragment key={level3.id}>
							{level3.dType == 0 &&
								<DropZone
									data={{
										path: currentPath,
										childrenCount: data.lNodeChildrens.length
									}}
									onDrop={handleDrop}
									className="ml-4"
									name={`Add new lvl 3 under ${data.name}`}
									menu={menu}
								/>
							}
							{renderLevel3(level3, currentPath)}

						</React.Fragment>
					);
				})
				:
				<>
					{data.dType == 0 &&
						<DropZone
							data={{
								path: `${path}-0`,
								childrenCount: data.lNodeChildrens.length,
							}}
							onDrop={handleDrop}
							className="horizontalDrag ml-4"
							name={`Add new lvl 3 under  ${data.name}`}

						/>

					}
				</>
			}
			{/*<DropZone*/}
			{/*	data={{*/}
			{/*		path: `${path}-${data.lNodeChildrens.length}`,*/}
			{/*		childrenCount: data.lNodeChildrens.length*/}
			{/*	}}*/}
			{/*	onDrop={handleDrop}*/}
			{/*	isLast*/}
			{/*	name={`Create new lvl 3 under ${data.name} kiki`}*/}
			{/*	className="ml-4"*/}
			{/*/>*/}
		</div>
	);
};
export default Level2Node;
