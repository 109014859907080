import React, { useState, useContext, useEffect } from "react";
import ViewContainer from "../components/container/ViewContainer";
import GlobalPopup from "../components/global/GlobalPopup/GlobalPopup";
import TableContainer from "../components/databaseTable/TableContainer";
import { ViewContext } from "../components/contexts/ViewContext";

const DatabaseTableView = () => {
  const [hasChanged, setHasChanged] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupTitle, setPopupTitle] = useState("");
  const [popupMessageType, setPopupMessageType] = useState("");
  const { setView } = useContext(ViewContext);

  const togglePopup = () => {
    setIsPopupOpen((value) => !value);
  }
  const openPopup = () => {
    setIsPopupOpen(true);
  }

  useEffect(() => {
    setView("DatabaseTable");
  }, [])

  return (
    <ViewContainer>
      {isPopupOpen && (
        <GlobalPopup
          Message={popupMessage}
          handleClose={togglePopup}
          Title={popupTitle}
          MessageType={popupMessageType}
        />
      )}
      <TableContainer
        hasChanged={hasChanged}
        setHasChanged={setHasChanged}
        setPopupMessage={setPopupMessage}
        togglePopup={openPopup}
        setPopupTitle={setPopupTitle}
        setPopupMessageType={setPopupMessageType}
      />
    </ViewContainer>
  );
};

export default DatabaseTableView;
