import axios from 'axios'
const urlStart = process.env.REACT_APP_URL;

async function GetUser() {
    var result = await axios.get(urlStart + "api/APIUser/GetUser", { withCredentials: true });
    return result.data.firstName.charAt(0) + result.data.lastName.charAt(0);
}

export {
    GetUser
}