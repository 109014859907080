import React from "react";
import classNames from "classnames";
import { useDrop } from "react-dnd";
import { Level3, Level1, Level2 } from "./ConstantsType";

const ACCEPTS = [Level1, Level2, Level3, "selectableItem", "node"];

const TrashDropZone = ({ data, onDrop,menu }) => {
    const [{ isOver }, drop] = useDrop({
        accept: ACCEPTS,
        type:'node',
        drop: (item, monitor) => {
            console.log(item);
            onDrop(data, item);
        },
        //canDrop: (item, monitor) => {
        //    const layout = data.layout;
        //    const itemPath = item.path;
        //    const splitItemPath = itemPath.split("-");
        //    const itemPathRowIndex = splitItemPath[0];
        //    const itemRowChildrenLength =
        //        layout[itemPathRowIndex] && layout[itemPathRowIndex].lNodeChildrens.length;

        //    // insert condition to not delete node or groupnode
        //    return true;
        //},
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            //canDrop: monitor.canDrop()
        })
    });

    const isActive = isOver /*&& canDrop*/;
    return (
        <div
            className={classNames("trashDropZone", { active: isActive }, "trashZone")}
            ref={drop}
        >
            Drop here to remove item
        </div>
    );
};
export default TrashDropZone;
