//export enum States {
//    None,
//    IsNew,
//    IsModified,
//    IsDeleted
//}
//export interface IProps {
//    Label?: string;
//    State: States;
//}

export const States = {
    None: 0,
    IsNew: 1,
    IsModified: 2,
    IsDeleted: 3,
}


//export const Detail = {
//    Type,
//    StartDate,
//    EndDate,
//    Label,
//}