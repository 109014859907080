import React, {useState, useCallback } from 'react'
import Effectoa from '../../global/Effectoa';
import Toast from '../../global/Toast';
import DropZone from "../../dragableItem/DropZone";
import TrashDropZone from "../../dragableItem/TrashDropZone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Level1Node from "../../dragableItem/Level1Node";
import { SELECTABLE_ITEM, Level1, Level3, Level2 } from "../../dragableItem/ConstantsType";
import {
	handleMoveWithinParent,
	handleMoveToDifferentParent,
	handleMoveSelectableChildrenIntoParent,
	handleRemoveItemFromLayout
} from "../../dragableItem/DragAndDropHelper";
import {
	SaveDragableMenu,
} from './MenuManagementHelper';


const MenuViewerRightContainer = ({ setLoading, menu, nodes, SELECTABLE_ITEMS, setMenu, menuInformationID, setNodes }) => {
	const [ showToast, setToast ] = useState(false);

	const handleDropToTrashBin = useCallback(
		(dropZone, item) => {
			const splitItemPath = item.path.split("-");
			setMenu(handleRemoveItemFromLayout(menu, splitItemPath));
		},
		[menu]
	);

	const renderLevel1 = (Level1, currentPath) => {
		return (
			<Level1Node
				key={Level1.id}
				data={Level1}
				handleDrop={handleDrop}
				nodes={nodes}
				path={currentPath}
				menu={menu}
			/>
		);
	};

	function AddNode(item, splitDropZonePath, pathToDropZone ) {

		//Move sidebar item into page
		const newNode = {
			id: item.id,
			...item.dataNode
		};
		newNode.menuInformationID = item.menuInformationsID != 0 ? item.menuInformationsID : menuInformationID;
		const newItem = {
			id: newNode.id,
			type: item.type,
			icon: item.icon,
			name: item.name,
			path: pathToDropZone,
			menuInformationsID: item.menuInformationsID != 0 ? item.menuInformationsID : menuInformationID,
			lNodeChildrens: [],
		};
		if (item.lNodeChildrens === undefined)
			newItem.lNodeChildrens = [];

		if (splitDropZonePath.length == 1) {
			newItem.path = splitDropZonePath[0];
		}
		else if (splitDropZonePath.length == 2)
			newItem.path = splitDropZonePath[1];
		else if (splitDropZonePath.length == 3)
			newItem.path = splitDropZonePath[2];

		setNodes({
			...nodes,
			[newNode.id]: newNode
		});
		setMenu(
			handleMoveSelectableChildrenIntoParent(
				menu,
				splitDropZonePath,
				newItem
			)
		);
	}

	const handleDrop = useCallback(
		(dropZone, item) => {

			const splitDropZonePath = dropZone.path.split("-");
			const pathToDropZone = splitDropZonePath.slice(0, -1).join("-");
			const newItem = {
				id: item.id,
				type: item.type,
				icon: item.icon,
				name: item.name,
				path: pathToDropZone,
				menuInformationsID: item.menuInformationsID != 0 ? item.menuInformationsID : menuInformationID,
				lNodeChildrens: item.lNodeChildrens,
			};
			if (item.lNodeChildrens === undefined)
				newItem.lNodeChildrens = [];

			if (item.type === Level2) {
				newItem.lNodeChildrens = item.lNodeChildrens;
			}

			if (item.type === Level1) {
				newItem.path = splitDropZonePath[0];
			}
			else if (item.type === Level2)
				newItem.path = splitDropZonePath[1];
			else if (item.type === Level3)
				newItem.path = splitDropZonePath[2];

			// sidebar into
			if (item.type === SELECTABLE_ITEM) {
				const found = menu.find(obj => {
					var itemExist;
					if (obj.id === item.id) {
						return obj.id === item.id;
					}
					for (var i = 0; i < obj.lNodeChildrens.length; i++) {
						if (obj.lNodeChildrens[i].id === item.id) {
							return obj.lNodeChildrens[i].id === item.id;
						}
					}

					return itemExist
				});
				if (found != null) {
					alert("Node already in your menu");
				} else if (item.dType == 0)
				{
					if (splitDropZonePath.length == 3)
						alert("Can't add a title node in lvl 3");
					else
						AddNode(item, splitDropZonePath, pathToDropZone)

				} else if (item.dType == 1) {
					if (splitDropZonePath.length == 1)
						alert("Can't add a linkable item in a title.");
					else
						AddNode(item, splitDropZonePath);
				}
				else {
					AddNode(item, splitDropZonePath, pathToDropZone);
					return;
				}
			} else { 

			// move down here since sidebar items dont have path
			const splitItemPath = item.path.split("-");
			const pathToItem = splitItemPath.slice(0, -1).join("-");

			// Pure move (no create)
			if (splitItemPath.length === splitDropZonePath.length) {
				// move within parent
				if (pathToItem === pathToDropZone) {
					setMenu(
						handleMoveWithinParent(menu, splitDropZonePath, splitItemPath)
					);
					return;
				}

				//OR move different parent
				setMenu(
					handleMoveToDifferentParent(
						menu,
						splitDropZonePath,
						splitItemPath,
						newItem
					)
				);
				return;
			}

			// Move + Create
			setMenu(
				handleMoveToDifferentParent(
					menu,
					splitDropZonePath,
					splitItemPath,
					newItem
				)
			);
			}
		},
		[menu, nodes]
	);

	return (<>
		<div className="element-card-title">
			Create your Menu
			<Effectoa
				className="clickable d-flex align-items-center"
				onClick={() => {
					setLoading(true);
					if (SaveDragableMenu(menu))
						setLoading(false);

				}}
				caption=""
				clickOverride={true}
			>
				<FontAwesomeIcon icon="fa-solid fa-floppy-disk" className=" icon icon-lg align-right" />
			</Effectoa>

		</div>
		<div className="page-container-content">
			<DropZone
				data={{
					path: `${menu.length}`,
					childrenCount: menu.length
				}}
				onDrop={handleDrop}
				isLast
				name="Add new lvl 1"
				menu={menu}
			/>
			{SELECTABLE_ITEMS && menu.map((Level1, index) => {
				const currentPath = `${index}`;

				return (
					<React.Fragment key={Level1.id}>
						{renderLevel1(Level1, currentPath)}
						{/*<DropZone*/}
						{/*	data={{*/}
						{/*		path: currentPath,*/}
						{/*		childrenCount: menu.length*/}
						{/*	}}*/}
						{/*	onDrop={handleDrop}*/}
						{/*	path={currentPath}*/}
						{/*	name={`Add new lvl 2 under ${Level1.name} `}*/}
						{/*/>*/}
					</React.Fragment>
				);
			})}
		</div>
		<div className="element-card-foot">
			<TrashDropZone
				data={{
					menu
				}}
				onDrop={handleDropToTrashBin}
				menu={menu}
			/>
		</div>

		{ showToast &&
			<Toast title="d" message="2" />
		}
	</>)
}

export default MenuViewerRightContainer;