import React, { useEffect } from "react";
import CheckBox from "./CheckBox";

const GroupCheckbox = ({
  field_classname,
  field_id,
  field_value,
  field_options,
}) => {
  if (field_options !== []) {
    return (
      <div>
        {field_options.map((option, key) => {
          return (
            <CheckBox
              key={key}
              field_id={option.field_id}
              field_value={option.field_value}
              field_label={option.field_label}
              field_label_classname={option.field_label_classname}
              field_className={option.field_className}
            />
          );
        })}
      </div>
    );
  }
};

export default GroupCheckbox;
