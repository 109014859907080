import React from "react";
import img from "../../img/DashBoard.PNG";

const LoginDecoration = () => {
  return (
    <div
      className="d-flex align-items-center"
      style={{ justifyContent: "center", minHeight: "100vh" }}
    >
      <img
        src={img}
        alt="Dashboard"
        style={{
          maxWidth: "100%",
          height: "auto",
          opacity: "60%",
        }}
      />
    </div>
  );
};

export default LoginDecoration;
