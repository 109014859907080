import React, { useContext } from "react";
import { Navigate, useNavigate } from "react-router";
import { Effectoa } from "../global/indexGlobal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuSelectedContext } from "../contexts/MenuSelectedContext";

const SideBarViewItem = ({ caption, icon, url, SelectedNode }) => {
  const { setSelectedMenuItem } = useContext(MenuSelectedContext);
  const nav = useNavigate();

  return (
    <Effectoa
      className={`nav-link ${SelectedNode == url ? "Active" : ""}`}
      value={url}
      onClick={(e) => {
        if (e.currentTarget.attributes["value"].value != null) {
          setSelectedMenuItem(e.currentTarget.attributes["value"].value);
          nav("/" + url);
        }
      }}
      caption={caption}
    >
      <FontAwesomeIcon icon={icon} className="nav-icon" />
    </Effectoa>
  );
};

export default SideBarViewItem;
