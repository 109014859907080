import React, { useState, useCallback, useEffect } from "react";
import Effectoa from '../../global/Effectoa';
import Select from 'react-select';
import DropZone from "../../dragableItem/DropZone";
import TrashDropZone from "../../dragableItem/TrashDropZone";
import SelectableItem from "../../dragableItem/SelectableItem";
import Level1Node from "../../dragableItem/Level1Node";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EffectoInput from "../../global/EffectoInput";
import FormTemplate from "../../global/form/FormTemplate";
import axios from "axios";

import fieldsJSON from "./MenuCreateFields.json";
import { ValidateFields, GetMenuNodeById  } from "./MenuManagementHelper";

const urlStart = process.env.REACT_APP_URL;
//import { LoadingContext } from "../../contexts/LoadingContext";

const MenuCreate = ({ selectableItems, setSelectableItems, setEditing, nodeId }) => {
    const [error, setError] = useState(null);
    const [requestError, setRequestError] = useState(null);
    const [elements, setElements] = useState(fieldsJSON[0]);
    const [node, setNode] = useState(null);

    //const nav = useNavigate();
    //const { loading, setLoading } = useContext(LoadingContext);


    async function load(nodeId, element) {
        var result = await GetMenuNodeById(nodeId);
        if (result) {
            setNode(result.data);
            element.title = "Edit menu item";
            element.fields[0]["field_value"] = result.data.caption;
            /*console.log(result.data.Type);*/

            //elements.fields[1]["field_options"] = ["Dashboard", "Dashboard"];
            //element.fields[1]["label"] = "Dashboard";
            //element.fields[1]["field_value"] = "Dashboard";
            //element.fields[1]["value"] = "Dashboard";

            

            element.fields[3]["field_value"] = result.data.description;
            element.fields[6]["field_value"] = result.data.area;
            element.fields[7]["field_value"] = result.data.controller;
            element.fields[5]["field_value"] = result.data.action;
            element.fields[4]["field_value"] = result.data.icon;
            element.fields[9]["field_value"] = result.data.active;

            element.fields[11]["field_value"] = "Save item";
            
        }
    }
    
    useEffect(() => {

        setElements(fieldsJSON[0]);

        //elements.fields[1]["field_value"] = "Type ";
        //elements.fields[1]["field_options"] = [1, "asd"];
        if (nodeId !== 0) {
            load(nodeId, elements);
        }
    }, []);

    const handleChange = (id, event) => {
        const newElements = { ...elements };
        newElements.fields.forEach((field) => {
            const { field_type, field_id } = field;
            if (id === field_id) {
                switch (field_type) {
                    case "checkbox":
                        field["field_value"] = event.target.checked;
                        break;
                    case "dropdown":
                        console.log(event.target.label);
                        field["field_value"] = event.target.label;
                        elements.fields[11]["field_value"] = "Co Menu";
                        break;


                    default:
                        field["field_value"] = event.target.value;
                        break;
                }
            }
            setElements(newElements);
        });
    };

    const handleSubmit = (event) => {
        var menuNode = {
            Id: 0,
            //Id: nodeId,
            Caption: elements.fields[0]["field_value"],
            Description: elements.fields[3]["field_value"],
            Area: elements.fields[6]["field_value"],
            Controller: elements.fields[7]["field_value"],
            Action: elements.fields[5]["field_value"],
            Icon: elements.fields[4]["field_value"],
            Active: elements.fields[9]["field_value"],
            MenuType: 0,
            ElementViewerID: null,
            ElementViewer: null, 
            Roles: [],
            Type: elements.fields[1]["field_value"],
            lNodeChildrens: [],
            NodeRoles: "",
            DeleteNode: false,
            ClassName: ""
        };  // ElementViewer,Roles, NodeRoles, MenuType


        setError(null);
        setRequestError(null);
        event.preventDefault();
        const error = ValidateFields(elements.fields);
        if (error.length == 0) {
            setError(null);
            axios
                .post(
                    urlStart + "api/APIMenuManagement/CreateMenuNode/",
                    menuNode,
                    { withCredentials: true }
                )
                .then((res) => {
                    if (res) {
                        setSelectableItems([...selectableItems, res.data]);
                        setEditing(false);
                    }
                })
                .catch((err) => {
                    setRequestError({
                        statusCode: err.response.request.status,
                        message: err.response.data.message,
                    });
                })
                .finally(() => {
                    //setLoading(false);
                });
        } else {
            setError(error);
            //setLoading(false);
        }
    };

    return (
        <>
            <div className="page-container">
                <div className="page-card-container width-100 height-100">
                    <FormTemplate 
                        //className={"login"}
                        //classNameTitle="mettre un style dufférent ici si on veut"
                        elements={elements}
                        setElements={setElements}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        error={error}
                        cancel={setEditing}
                    />
                    {requestError && (
                        <span className="text-danger d-block error">
                            {requestError.message ?? "Erreur"}
                        </span>
                    )}
                </div>
            </div>
        </>
        );
}

export default MenuCreate;