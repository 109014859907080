import React, { useRef } from "react";
import { useDrag } from "react-dnd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Level3 } from "./ConstantsType";

const Level3Node = ({ data, nodes, path }) => {
    const ref = useRef(null);

    const [{ isDragging }, drag] = useDrag({
        item: {
            type: Level3,
            id: data.id,
            path,
            icon: data.icon,
            name: data.name,
            menuInformationsID: data.menuInformationsID,
            lNodeChildrens: data.lNodeChildrens,
            dType: data.dType,
        },
        type: "selectableItem",
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    });

    const opacity = isDragging ? 0 : 1;

    return (
        <div
            ref={drag}
            style={{ opacity }}
            className="component draggable draggableItem ml-4"
        >
            {data.icon &&
                <FontAwesomeIcon icon={data.icon} className="icon icon-lg" />
            }
            <span className="ml-1">
                {data.name}
            </span>
        </div>
    );
};
export default Level3Node;
