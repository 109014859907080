import $ from "jquery";
import axios from "axios";
//var ressources;

/*if (window.sessionStorage.getItem('Lang') == null) {
	window.sessionStorage.setItem('Lang', 'en-EN');
}*/

/*var langs = new Array();
var lang = new Array();
for (var i = 0; i < lang.length; i++) {
	langs.push(lang[i].isoCode);
}


var current_lang_index;*/

/*if (window.sessionStorage.getItem('Lang') === null || window.sessionStorage.getItem('Lang') == 0) {
	window.sessionStorage.setItem('Lang', 'en-EN');
}*/
/*window.sessionStorage.setItem('Lang', 'en-EN');
var current_lang = window.sessionStorage.getItem('Lang');*/

/*export function change_lang() {
  translate();
}*/

/*export function translate() {
  var lang = window.sessionStorage.getItem("Lang");
  if (lang == null) {
    lang = "en-EN";
    window.sessionStorage.setItem("Lang", lang);
  }
  var path = "languages/" + lang + ".json";
  $.getJSON(path, function (data) {
    ressources = data;
  }).done(function () {
    $("[data-translate]").each(function () {
      var key = $(this).data("translate");
      if (ressources[key] != null) {
        $(this).html(ressources[key]["value"]);
      } else {
        $(this).html(key);
      }
    });
  });
}*/

//function translateLive() {
//	$(".live[data-translate]").each(function () {
//		var key = $(this).data('translate');
//		if (ressources[key] != null) {
//			$(this).html(ressources[key]['value']);
//		} else {
//			$(this).html(key);
//		}
//	});

//}

/*$(document).ready(function () {
	//$('#sLanguages option:selected').prop('selected', false);
	//$('#sLanguages option').each(function () {
	//	if ($(this).prop('value') == window.sessionStorage.getItem('Lang')) {
	//		$(this).prop('selected', true);
	//	}
	//});
	translate();

	//$('.dx-icon-trash').on('click', function () { console.log('ok'); });
});*/

/*$('.dx-icon-trash').on('click', function () { console.log('ok'); });*/

//function multipleTranslate(input) {
//	var key = $(input).attr('data-translate');
//	var currentInput = $(input).val();
//	if ($('[data-translate="' + key + '"]').length > 1) {
//		$('[data-translate="' + key + '"]:not(:first)').each(function () { $(this).val(currentInput) });
//	}
//};

//function InitializeTranslate() {
//	$('.dx-header-row td').each(function () {
//		if ($(this).attr('role') == 'columnheader') {
//			var dataTranslate = "";
//			if ($(this).attr('aria-label') != null) {
//				var ArraydataTranslate = $(this).attr('aria-label').split(' ');
//				for (i = 1; i < ArraydataTranslate.length; i++) {
//					dataTranslate += ArraydataTranslate[i];
//				}
//				$(this).attr('data-translate', dataTranslate);
//				$(this).off();
//				var key = $(this).data('translate');
//				if (ressources[key] != null) {
//					$(this).html(ressources[key]['value'] || key);
//				}
//			}

//		}
//	});
//}

//function EditRessources() {

//	$('.navbar').css('background-color', '#004ea1');
//	$('.ddlLang').css('background-color', '#004ea1');
//	$('.ddlLang').addClass('cursor');
//	$('#btnSaveTraduction').removeClass('disabledInput');

//	$('[data-translate]').each(function () {
//		var key = $(this).data('translate');
//		$(this).empty();

//		if ($(this).is('label')) {
//			$(this).css('width', '70%');
//			$('.containerForm').removeClass('disabledInput');
//			$(this).addClass('TransForm');
//		}
//		if ($(this).is('button')) {
//			$(this).addClass('disabled');
//			$(this).attr('disabled', 'true');
//		}
//		if ($(this).is('a') || $(this).is('button')) {
//			$("a").removeAttr('href');
//		}
//		if ($(this).is('span')) {
//			$("span").parent().removeAttr('href');
//		}
//		var classes = $(this).attr('class');

//		if (ressources[key] != null) {
//			if ($(this).is('option')) {
//				$(this).parent('select').after('  <input data-translate="' + $(this).attr('data-translate') + '" class="form-translate translate-border-success active-pointer  mb-1 ml-4 ' + classes + '" type="text" value="' + ressources[key]['value'] + '" onchange="multipleTranslate($(this));" />');

//			} else if ($(this).is('td') && $(this).width() > 20) {
//				var width = $(this).width();
//				$(this).replaceWith('  <input data-translate="' + $(this).attr('data-translate') + '" class="form-translate translate-border-success active-pointer  mb-1 ml-4 ' + classes + '" type="text" value="' + ressources[key]['value'] + '" onchange="multipleTranslate($(this));" />');
//				$(this).width(width);
//			} else{
//				$(this).append('  <input data-translate="' + $(this).attr('data-translate') + '" class="form-translate translate-border-success active-pointer ' + classes + '" type="text" value="' + ressources[key]['value'] + '" onchange="multipleTranslate($(this));" />');
//			}
//		} else {
//			if ($(this).is('option')) {
//				$(this).parent('select').after('  <input data-translate="' + $(this).attr('data-translate') + '" class="form-translate translate-border-danger active-pointer  mb-1 ml-4' + classes + '" type="text" value="' + ressources[key]['value'] + '" onchange="multipleTranslate($(this));" />');
//			} else {
//				$(this).append('  <input data-translate="' + $(this).attr('data-translate') + '" class="form-translate translate-border-danger active-pointer ' + classes + '" type="text" value="' + $(this).attr('data-translate') + '" onchange="multipleTranslate($(this));" />');
//			}
//		}
//		$(this).removeAttr('data-translate');
//		if ($('[data-translate="' + key + '"]').length > 1) {
//			$('[data-translate="' + key + '"]:not(:first)').each(function () { $(this).addClass('translate-multiple'); $(this).addClass('disabledInput'); $(this).addClass('DontTake'); });
//			$('[data-translate="' + key + '"]:not(:first)').each(function () { $(this).removeClass('translate-border-success'); $(this).removeClass('translate-border-danger'); $(this).removeClass('active-pointer'); });
//		}

//		if ($(this).is('label')) {
//			$(this).removeClass('TransForm');
//		}
//		if ($(this).is('td')) {
//			$(this).children('input').width($(this).width() - 60);
//		}

//		$(this).removeClass('translate-multiple');
//	});
//}

/*export function SaveRessourcesOnSaveMenu() {
  var path = "/languages/" + window.sessionStorage.getItem("Lang") + ".json";
  $.getJSON(path, function (data) {
    ressources = data;
  });

  $("[data-translate]").each(function () {
    var data = $(this).text();
    var key = $(this).attr("data-translate");
    if (!$(this).hasClass("DontTake")) {
      if (ressources[key] != null) {
        if (ressources[key]["value"] != data) {
          ressources[key]["value"] = data;
        }
      } else {
        ressources[key] = { value: data };
      }
    }
  });
  modifyJson();
}*/

export function getValuebyDataTranslate(ressources, dataTranslate) {
  var value = null;
  if (ressources != undefined) {
    if (ressources[dataTranslate] != null) {
      value = ressources[dataTranslate]["value"];
    }
  }
  if (value == null) {
    return dataTranslate;
  } else {
    return value;
  }
}

export function SaveRessources(ressources, url, lang) {
  if (ressources != null) {
    
    var newRessource = JSON.parse(JSON.stringify(ressources));
    $("input[data-translate]").each(function () {
      var data = this.value;
      var key = $(this).attr("data-translate");
      if (!$(this).hasClass("DontTake")) {
        if (key && data) {
          if (ressources[key]) {
            if (ressources[key]["value"] != data) {
              newRessource[key]["value"] = data;
            }
          } else {
            newRessource[key] = { value: data };
          }
        }
      }
    });
    modifyJson(newRessource, url, lang);
  }
}
function modifyJson(newRessource, url, lang) {
  axios
    .post(
      url + "Administration/Home/UpdateJson",
      {
        lang: lang,
        ressources: JSON.stringify(newRessource),
      },
      { withCredentials: true }
    )
    .then((res) => {})
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      location.reload();
    });
}
