import React from "react";
import DowntimeForm from "./DowntimeForm"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const DowntimeUpdateForm = ({ setPopupMessage, togglePopup, setPopupTitle, setPopupMessageType, toggleUpdateOpen, dataDowntimeUpdate, setDowntimeInserted }) => {
    

    return (
        <div>
        <FontAwesomeIcon icon="fa-solid fa-circle-x" className="closeUpdate-icon" onClick={toggleUpdateOpen}/>
        <DowntimeForm setPopupMessage={setPopupMessage} togglePopup={togglePopup} setPopupTitle={setPopupTitle} setPopupMessageType={setPopupMessageType} dataDowntimeUpdate={dataDowntimeUpdate} toggleUpdateOpen={toggleUpdateOpen} setDowntimeInserted={setDowntimeInserted} formType="update"/>
        </div>
    )
}

export default DowntimeUpdateForm;