import React from 'react';
import { useLocation, useNavigate } from 'react-router';

const ErrorView = () => {
    const location = useLocation();
    const nav = useNavigate();


    return (
        <div className="errorView">
            <h1>INTERNAL SERVER ERROR</h1>
            <h3>Please contact an administrator</h3>
        </div>
    );
}

export default ErrorView;