import React, { useState, useEffect } from "react";
import DatabaseTable from "./DatabaseTable";
import DatabaseTableDdl from "./DatabaseTableDdl";
import "devextreme/dist/css/dx.light.css";
import "../../Css/datagrid.css";
import useAxios from "../hooks/useAxios";
import { loadTableData, loadColumns, loadForeignKeys, loadForeignData } from "./DatabaseTableHelper";
const urlStart = process.env.REACT_APP_URL;
const urlTableData = urlStart + "api/APITableEditor/GetTableData/";
const urlColumns = urlStart + "api/APITableEditor/GetColumns";
const urlForeignKeys = urlStart + "api/APITableEditor/GetForeignKeys";
const urlForeignData = urlStart + "api/APITableEditor/GetForeignData";
const urlTable = urlStart + "api/APITableEditor/GetAvailableTablesNames";

const TableContainer = ({
  hasChanged,
  setHasChanged,
  setPopupMessage,
  togglePopup,
  setPopupTitle,
  setPopupMessageType,
}) => {
  const [tableData, setTableData] = useState();
  const [Columns, setColumns] = useState();
  const [columnOrder, setColumnOrder] = useState("");
  const [foreignKeys, setForeignKeys] = useState();
  const [foreignData, setForeignData] = useState();
  const [tables, setTables] = useState([]);
  const [tableName, setTableName] = useState("");
  const [idName, setIdName] = useState("");

  const { response: Tables } = useAxios({ url: urlTable, method: "get" });

  async function load() {
    var table = "";
    var condition = "";
    if (tableName != "") {
      table = tableName.Name;
      condition = tableName.Condition;
      setColumnOrder(tableName.ColumnOrder ?? "");
    }
    var columnsData = await loadColumns(urlColumns, table, columnOrder);
    if (columnsData) {
      setColumns(columnsData.data);
      setIdName(columnsData.data[0].Column_Name);
    }
    var foreignKeysData = await loadForeignKeys(urlForeignKeys, table);
    if (foreignKeysData) {
      setForeignKeys(foreignKeysData.data);
    }
    var foreignDataData = await loadForeignData(urlForeignData, table);
    if (foreignDataData) {
      setForeignData(foreignDataData.data);
    }
    var tableData = await loadTableData(urlTableData, table, condition);
    if (tableData) {
      setTableData(tableData.data);
    }
  }

  useEffect(() => {
    if (Tables !== null) {
      setTables(Tables);
    }
    if (hasChanged) {
      load();
      setHasChanged(false);
    }
  }, [Tables, hasChanged]);

  return (
    <>
      <DatabaseTable
        foreignKeys={foreignKeys}
        foreignData={foreignData}
        tableData={tableData}
        columns={Columns}
        columnOrder={columnOrder}
        tableName={tableName}
        idName={idName}
        setHasChanged={setHasChanged}
        setPopupMessage={setPopupMessage}
        togglePopup={togglePopup}
        setPopupTitle={setPopupTitle}
        setPopupMessageType={setPopupMessageType}
      >
        <DatabaseTableDdl
          items={tables}
          tableName={tableName}
          setTableName={setTableName}
          setHasChanged={setHasChanged}
        />
      </DatabaseTable>
    </>
  );
};

export default TableContainer;
