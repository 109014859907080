import React, { useState, useEffect } from "react";
import { TopBarDropdown } from "./index";
import DatabaseTableButton from "../global/DatabaseTableButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GetUser } from "../user/UserHelper";

const TopBarItemList = ({ items, selectedDropDown, setSelectedDropDown }) => {
  const role = sessionStorage.getItem("Role");
  const [Initial, SetInitial] = useState();

  async function load() {
    var User = await GetUser();
    if (User) {
      console.log(User);
      SetInitial(User);
    }
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <>
      {items.map((data, key) => {
        if (data.name == "user") {
          data.caption = Initial;
        }
        if (data.name == "langue") {
          if (role == "Owner" || role == "Administrator") {
            switch (data.type) {
              case "dropdown":
                return (
                  <TopBarDropdown
                    key={key}
                    icon={data.icon}
                    classname={data.classname}
                    onClick={setSelectedDropDown}
                    dropDownMenu={data}
                    dropDownName={data.name}
                    selectedDropDown={selectedDropDown}
                    caption={data.caption}
                  />
                );
              default:
                return (
                  <a key={key} className={data.classname}>
                    <FontAwesomeIcon
                      icon={data.icon}
                      className="icon icon-lg"
                    />
                  </a>
                );
            }
          }
        } else {
          switch (data.type) {
            case "dropdown":
              return (
                <TopBarDropdown
                  key={key}
                  icon={data.icon}
                  classname={data.classname}
                  onClick={setSelectedDropDown}
                  dropDownMenu={data}
                  dropDownName={data.name}
                  selectedDropDown={selectedDropDown}
                  caption={data.caption}
                />
              );
            default:
              return (
                <a key={key} className={data.classname}>
                  <FontAwesomeIcon icon={data.icon} className="icon icon-lg" />
                </a>
              );
          }
        }
      })}
    </>
  );
};

export { TopBarItemList };
