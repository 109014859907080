import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Effectoa } from "../global/indexGlobal";
const urlStart = process.env.REACT_APP_URL


const MenuDropDownMenuManagement = ({ title, icon }) => {
    const role = sessionStorage.getItem("Role");
    if (
        role == "Owner" ||
        role == "Administrator"
    ) {
        return (
            <Effectoa
                className="dropdown-item clickable d-flex align-items-center"
                caption={title}
                onClick={() => {
                    window.open( "/Administration/MenuManagement", "_blank");
                }}
            >
                <FontAwesomeIcon icon={icon} className="icon me-2" />
            </Effectoa>
        );
    } else {
        return <></>;
    }
};

export { MenuDropDownMenuManagement };
