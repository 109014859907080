import React, { useState, useContext, useEffect } from "react";
import ViewContainer from "../components/container/ViewContainer";
import DowntimeContainer from "../components/downtime/DowntimeContainer";
import DowntimeInsertForm from "../components/downtime/DowntimeInsertForm";
import GlobalPopup from "../components/global/GlobalPopup/GlobalPopup"
import DowntimeHistoryContainer from "../components/downtime/DowntimeHistoryContainer";
import DowntimeHistoryTable from "../components/downtime/DowntimeHistoryTable";
import DowntimeUpdateForm from "../components/downtime/DowntimeUpdateForm";
import { ViewContext } from "../components/contexts/ViewContext";

const DowntimeView = () => {

    const { setView } = useContext(ViewContext);

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupMessage, setPopupMessage] = useState("");
    const [popupTitle, setPopupTitle] = useState("");
    const [popupMessageType, setPopupMessageType] = useState("");

    const [downtimeInserted, setDowntimeInserted] = useState(true);
    const [downtimeUpdateOpen, setDowntimeUpdateOpen] = useState(false);
    const [dataDowntimeUpdate, setDataDowntimeUpdate] = useState("");

    const today = new Date();
    const defaultDate = new Date(today).toISOString().split('T')[0] // yyyy-mm-dd

    const [downtimeDate, setDowntimeDate] = useState(defaultDate);

    const togglePopup = () => {
        setIsPopupOpen((value) => !value);
    }

    const toggleUpdateOpen = () => {
        setDowntimeUpdateOpen((value) => !value);
    }

    useEffect(() => {
        setView("Downtime");
    }, [])

    return (
        <ViewContainer>
            {downtimeUpdateOpen && <DowntimeUpdateForm className="updatePopup" setPopupMessage={setPopupMessage} togglePopup={togglePopup} setPopupTitle={setPopupTitle} setPopupMessageType={setPopupMessageType} dataDowntimeUpdate={dataDowntimeUpdate} toggleUpdateOpen={toggleUpdateOpen} setDowntimeInserted={setDowntimeInserted} />}
            {isPopupOpen && <GlobalPopup Message={popupMessage} handleClose={togglePopup} Title={popupTitle} MessageType={popupMessageType} />}
            <div className={downtimeUpdateOpen ? "enableBlur" : "disableBlur"}>
                <DowntimeContainer>
                    <DowntimeInsertForm setPopupMessage={setPopupMessage} togglePopup={togglePopup} setPopupTitle={setPopupTitle} setPopupMessageType={setPopupMessageType} setDowntimeInserted={setDowntimeInserted} />
                </DowntimeContainer>
                <DowntimeHistoryContainer>
                    <DowntimeHistoryTable downtimeInserted={downtimeInserted} setDowntimeInserted={setDowntimeInserted} toggleUpdateOpen={toggleUpdateOpen} setDataDowntimeUpdate={setDataDowntimeUpdate} downtimeDate={downtimeDate} setDowntimeDate={setDowntimeDate} />
                </DowntimeHistoryContainer>
            </div>
        </ViewContainer>
    );
}

export default DowntimeView;