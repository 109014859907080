export function ValidateFields(fields) {
  var error = [];
  fields.forEach((field) => {
    switch (field["field_id"]) {
      case "username":
        if (field["field_value"] == "") {
          error.push({
            field_id: "username",
            error_message: "The Username field is required.",
          });
        }
      case "password":
        if (field["field_value"] == "") {
          error.push({
            field_id: "password",
            error_message: "The Password field is required.",
          });
        }
      case "confirmPassword":
        if (field["field_value"] == "") {
          error.push({
            field_id: "confirmPassword",
            error_message: "Please confirm your password",
          });
        }
    }
  });
  return error;
}
