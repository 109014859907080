import React from 'react'
import SelectableItem from "../../dragableItem/SelectableItem";
import Effectoa from '../../global/Effectoa';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MenuViewerLeftContainer = ({ setEditing, SELECTABLE_ITEMS, setNodeId }) => {

	return (<>
		<div className="element-card-title">
			All Menu Elements
			<Effectoa
				className="clickable d-flex align-items-center"
				onClick={() => {
					setEditing(true);
				}}
				caption=""
				clickOverride={true}
			>
				<FontAwesomeIcon
					icon="fa-solid fa-plus"
					className=" icon icon-lg align-right" />
			</Effectoa>

		</div>

		<div className="page-container-content">
			{SELECTABLE_ITEMS && SELECTABLE_ITEMS.map((selectableItem) => {
				return (
					<SelectableItem
						key={selectableItem.id}
						data={selectableItem}
						setEditing={setEditing}
						setNodeId={setNodeId} />
				)
			}
			)}
		</div>
	</>)
}

export default MenuViewerLeftContainer;