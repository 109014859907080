import React, { useContext, useEffect } from "react";
import ViewContainer from "../components/container/ViewContainer";
import { ViewContext } from "../components/contexts/ViewContext";
import MenuContainer from "../components/administration/menuManagement/MenuContainer";


const MenuManagementView = () => {
    const { setView } = useContext(ViewContext);

    useEffect(() => {
        setView("MenuManagement");
    }, [])

    return (
        <ViewContainer>
            <div className="page-title">
                <div className="page-title-container">
                    {/*<div className="page-title-container-before">*/}
                    {/*</div>*/}
                    <div className="page-title-container-text">
                        Menu management
                    </div>
                </div>
            </div>
            <MenuContainer />
        </ViewContainer>
        )
}

export default MenuManagementView;