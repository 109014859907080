import React, { useContext, useEffect } from "react";
import Dashboard from "../components/Dashboard";
import { ViewContext } from "../components/contexts/ViewContext";
import "../Css/dashboardsAndReportsManager.css";

const DashboardView = () => {

    const { setView } = useContext(ViewContext);
    useEffect(() => {
        setView("DashboardView");
    }, [])

    return (
        <div style={{ height: "100%" }}>
            <Dashboard className="DontTake"  />
        </div>
        
    );
};

export default DashboardView;