import React, { useEffect, useState } from "react";
import { States } from "./Constants";
import TableEditItem from "./TableEditItem";
import { RessourceEditor } from "../../languages/RessourcesEditor"

const TableNewItemCard = ({ indexParent, indexItems, table, setRefresh, pauseTypes}) => {
  const [isAdding, setAdding] = useState(false);

  useEffect(() => {
    setAdding(false);
  }, [table])

  return (
    <>
      {!isAdding ? (
        <button className="table-button" onClick={() => setAdding(true)}>
          <RessourceEditor dataTranslate={"AddPeriod"}/>
        </button>
      ) : (
        <div className="table-card">
          <TableEditItem
            table={table}
            setInEdition={setAdding}
            state={States.IsNew}
            setRefresh={setRefresh}
            Title={"AddCardTitle"}
            TitleCss={"color-adding"}
            parentIndex={indexParent}
            indexItems={indexItems}
            pauseTypes={pauseTypes}
          />
        </div>
      )}
    </>
  );
};

export default TableNewItemCard;
