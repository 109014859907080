import React, { useState, useEffect } from "react";
import "devextreme/dist/css/dx.light.css";
import "../../Css/datagrid.css";
import {
  DataGrid,
  Editing,
  Scrolling,
  Paging,
  Toolbar,
  Column,
  Pager,
} from "devextreme-react/data-grid";
import GlobalPopup from "../global/GlobalPopup/GlobalPopup";
import { loadForeignData, loadTableData, processBatchUpdate } from "./DatabaseTableHelper";

const ReasonChildrenTable = (props) => {
  const urlStart = process.env.REACT_APP_URL;
  const UpdateUrl = urlStart + "api/APITableEditor/Save";
  const urlTableData = urlStart + "api/APITableEditor/GetTableData";
  const urlForeignData = urlStart + "api/APITableEditor/GetForeignData";
  const [tableData, setTableData] = useState();
  const [foreignData, setForeignData] = useState();
  const [HaveChanged, setHaveChanged] = useState(true);
  const idParent = props.data.key["idReason"];
  const idEquipment = props.data.key["idEquipment"];
  const idReasonType = props.data.key["idReasonType"];

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupTitle, setPopupTitle] = useState("");
  const [popupMessageType, setPopupMessageType] = useState("");
  const tableName = "tbl_Reason";
  const condition = "WHERE idParent = " + idParent;

  const togglePopup = () => {
    setIsPopupOpen((value) => !value);
  };

  async function load() {
    var childReasonData = await loadTableData(
      urlTableData,
      tableName,
      condition
    );
    if (childReasonData) {
      setTableData(childReasonData.data);
    } else {
      setTableData({
        Actif: true,
        Category: null,
        Description: null,
        ExtCode: null,
        ReasonCode: null,
        idEquipment: null,
        idParent: null,
        idReason: null,
        idReasonType: null,
      });
    }

    var foreignData = await loadForeignData(urlForeignData, tableName);
    if (foreignData) {
        setForeignData(foreignData.data);
    }
  }

  useEffect(() => {
    if (HaveChanged != null && HaveChanged) {
      load();
      setHaveChanged(false);
    }
  }, [HaveChanged]);

  const onSaving = React.useCallback((e) => {
    e.cancel = true;
    if (e.changes.length != 0) {
      var changes = e.changes;
      if (changes[0].type == "insert") {
        changes[0].data["idParent"] = idParent;
        changes[0].data["idEquipment"] = idEquipment;
        changes[0].data["idReasonType"] = idReasonType;
      }
      if (
        changes[0].type == "remove" ||
        changes[0].data["ReasonCode"]?.trim() != ""
      ) {
        e.promise = processBatchUpdate(
          UpdateUrl,
          changes,
          e.component,
          "tbl_Reason",
          "idReason"
        );

        if (e.promise) {
          e.promise
            .then(() => {
              setPopupMessageType("Success");
              setPopupTitle("Success");
              setPopupMessage("The database as succesfully been updated");
              togglePopup();
              setHaveChanged(true);
            })
            .catch((error) => {
              setPopupMessageType("Error");
              setPopupTitle(error.response.data.message);
              setPopupMessage(error.response.data.innerMessage);
              togglePopup();
            });
        }
      }
    }
  });

  return (
    <>
      {isPopupOpen && (
        <GlobalPopup
          Message={popupMessage}
          handleClose={togglePopup}
          Title={popupTitle}
          MessageType={popupMessageType}
        />
      )}
      <DataGrid
        id="datagrid"
        dataSource={tableData}
        showBorders={true}
        rowAlternationEnabled={true}
        onSaving={onSaving}
      >
        <Paging defaultPageSize={3} />
        <Pager
          visible={true}
          displayMode={"full"}
          showInfo={true}
          showNavigationButtons={true}
        />
        <Toolbar></Toolbar>
        <Column dataField="ReasonCode" dataType="string" />
        <Column dataField="Description" dataType="string" />
        <Column dataField="Category" dataType="int" />
        <Column dataField="ExtCode" dataType="string" />
        <Column dataField="Actif" />
        <Editing
          mode="row"
          allowUpdating={true}
          allowDeleting={true}
          allowAdding={true}
          selectTextOnEditStart={true}
          useIcons={true}
          confirmDelete={true}
        />
        <Scrolling mode="virtual" />
      </DataGrid>
    </>
  );
};

export default ReasonChildrenTable;
