import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Effectoa } from "../global/indexGlobal";
import axios from "axios";
import { useNavigate } from "react-router";
const urlStart = process.env.REACT_APP_URL

const MenuDropDownLogoutItem = ({ title, icon }) => {
  const nav = useNavigate();
  return (
    <Effectoa
      className="dropdown-item clickable d-flex align-items-center"
      caption={title}
      onClick={() => {
        axios
          .post(urlStart + "api/APIUser/Logout/", "Erase Cookie", {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            sessionStorage.clear();
            nav("/Login");
          });
      }}
    >
      <FontAwesomeIcon icon={icon} className="icon me-2" />
    </Effectoa>
  );
};

export { MenuDropDownLogoutItem };
