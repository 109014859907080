import React, { useState, useReducer, useEffect } from "react";
import TableColumn from "./TableColumn";
import useAxios from "../../hooks/useAxios";
import "./table.css";
const urlStart = process.env.REACT_APP_URL;
const pauseTypeUrl = urlStart + "api/APIWorkSchedule/GetPauseTypes";

const TableTemplate = ({ data, setRefresh }) => {
  const [pauseTypes, setPauseTypes] = useState([]);

  const { response, error: err } = useAxios({
    url: pauseTypeUrl,
    method: "get",
  });
  useEffect(() => {
    if (response !== null) {
      setPauseTypes(response);
    }
  }, [response]);

  return (
    <div className="table-container row mt-3">
      {data &&
        data.map((column, key) => (
          <TableColumn
            key={key}
            data={column}
            table={data}
            setRefresh={setRefresh}
            pauseTypes={pauseTypes}
          />
        ))}
    </div>
  );
};

export default TableTemplate;
