import React, { useRef } from "react";
import { useDrag } from "react-dnd";
import { Level1 } from "./ConstantsType";
import DropZone from "./DropZone";
import Level2Node from "./Level2Node";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Level1Node = ({ data, nodes, handleDrop, path,menu }) => {
    const ref = useRef(null);

    const [{ isDragging }, drag] = useDrag({
        item: {
            type: Level1,
            id: data.id,
            path: path,
            icon: data.icon,
            name: data.name,
            menuInformationsID: data.menuInformationsID,
            lNodeChildrens: data.lNodeChildrens,
            dType: data.dType,
        },
        type: "selectableItem",
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    });

    const opacity = isDragging ? 0 : 1;
    drag(ref);

    const renderLevel2 = (Level2, currentPath) => {
        return (
            <Level2Node
                key={Level2.id}
                data={Level2}
                nodes={nodes}
                handleDrop={handleDrop}
                path={currentPath}
                menu={menu}
            />
        );
    };

    //console.log("lvl 1 : ", data);
    //console.log("path : ", path)
    return (
        <div ref={ref} style={{opacity }} className="base draggable row">
            <div className="draggableItem">
                {data.icon &&
                    <FontAwesomeIcon icon={data.icon} className="icon icon-lg" />
                }
                <span className="ml-1">
                    {data.name}
                </span>
            </div>
            <div className="columns">
                {data.lNodeChildrens.length > 0 ?
                    data.lNodeChildrens.map((Level2, index) => {
                    const currentPath = `${path}-${index}`;

                    return (
                        <React.Fragment key={Level2.id}>
                            <DropZone
                                data={{
                                    path: currentPath,
                                    childrenCount: data.lNodeChildrens.length,
                                }}
                                onDrop={handleDrop}
                                className="horizontalDrag ml-2"
                                name={`Add new lvl 2 under ${data.name}`}
                                menu={menu}

                            />
                            {renderLevel2(Level2, currentPath)}
                        </React.Fragment>
                    );
                })
                    :

                <DropZone
                    data={{
                        path: `${path}-0`,
                        childrenCount: data.lNodeChildrens.length,
                    }}
                    onDrop={handleDrop}
                    className="horizontalDrag ml-2"
                        name={`Add new lvl 2 under ${data.name}`}

                />
                }
                {/*<DropZone*/}
                {/*    data={{*/}
                {/*        path: `${path}-${data.lNodeChildrens.length}`,*/}
                {/*        childrenCount: data.lNodeChildrens.length*/}
                {/*    }}*/}
                {/*    onDrop={handleDrop}*/}
                {/*    className="horizontalDrag ml-2"*/}
                {/*    isLast*/}
                {/*    name={`Add new lvl 2 under ${data.name}`}*/}
                {/*/>*/}
            </div>
        </div>
    );
};
export default Level1Node;
