import React, { useState, useContext } from 'react'
import { RessourceEditor } from "../languages/indexLang";
import { RessourcesContext } from "../contexts/RessourcesContext"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../../Css/toast.css'


const Toast = (title, message) => {
	const { isRessourcesEditing } = useContext(RessourcesContext);
	const { isVisible, setVisible } = useState(true);

	return (
		<>
			{isVisible ?
				<div className="toast-container">
					<div className="toast-title">
						<RessourceEditor dataTranslate={title} />
						<FontAwesomeIcon icon="fa fa-xmark" className="icon icon" />
					</div>
					<div className="toast-message">
						<RessourceEditor dataTranslate={message} />
					</div>
				</div>
				:
				<></>
			}
		</>
	)
}

export default Toast;