import React, { useContext, useState, useEffect } from "react";
import { FormContext } from "../../contexts/FormContext";

const Input = ({
  field_id,
  field_value,
  field_placeholder,
  field_type,
  className,
}) => {
  const { handleChange, error } = useContext(FormContext);
  const [field_error, setField_error] = useState(null);

  useEffect(() => {
    if (error !== null && error.length != 0) {
      var isInError = false;
      error.forEach((err) => {
        if (err.field_id == field_id) {
          setField_error(err);
          isInError = true;
        }
      });
      if (isInError != true) {
        setField_error(null);
      }
    } else {
      setField_error(null);
    }
  }, [error]);

  return (
    <>
      <input
        className={className}
        type={field_type}
        id={field_id}
        placeholder={field_placeholder ? field_placeholder : ""}
        value={field_value}
        onChange={(event) => handleChange(field_id, event)}
      />
      {field_error && (
        <span className="text-danger d-block field-validation-error">
          {field_error.error_message}
        </span>
      )}
    </>
  );
};

export default Input;
