import React, { useState, useEffect } from "react";
import axios from "axios";
import "./DowntimeHistoryTable.css";
import {
  DataGrid,
  Editing,
  Paging,
  Column,
  Pager,
  Button,
  HeaderFilter,
} from "devextreme-react/data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DowntimeInput from "../global/EffectoInput";
import { GetSpecificTableData } from "../databaseTable/DatabaseTableHelper";

const DowntimeHistoryTable = ({
	downtimeInserted,
	setDowntimeInserted,
	toggleUpdateOpen,
	setDataDowntimeUpdate,
	downtimeDate,
	setDowntimeDate,
}) => {
	const [tableData, setTableData] = useState();
	const urlStart = process.env.REACT_APP_URL;
	const urlDowntimeData = urlStart + "api/APITableEditor/GetDowntimeData";

	async function load() {
		var downtimeData = await GetSpecificTableData(
			urlDowntimeData + "/" + downtimeDate
		);
		if (downtimeData) {
			setTableData(downtimeData.data);
			setDowntimeInserted(false);
		}
	}

	useEffect(() => {
		if (downtimeDate != "") {
			load();
		}
	}, [downtimeDate, downtimeInserted]);

	function startEditing(e) {
		setDataDowntimeUpdate(e.row.key);
		toggleUpdateOpen();
	}

	function changeDate(type) {
		switch (type) {
			case "add":
				var date = new Date(downtimeDate);
				date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
				var newDate = new Date(
					date.getUTCFullYear(),
					date.getUTCMonth(),
					date.getUTCDate() + 1
				);
				setDowntimeDate(newDate.toISOString().substr(0, 10));
				break;
			case "remove":
				var date = new Date(downtimeDate);
				date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
				var newDate = new Date(
					date.getUTCFullYear(),
					date.getUTCMonth(),
					date.getUTCDate() - 1
				);
				setDowntimeDate(newDate.toISOString().substr(0, 10));

				break;
			default:
		}
	}

  return (
    <div>
      <div className="searchDateContainer">
        <button className="invisibleButton">
          <FontAwesomeIcon
            icon="fa-solid fa-angle-left"
            className="buttonSearch"
            onClick={() => changeDate("remove")}
          >
            -
          </FontAwesomeIcon>
        </button>
        <DowntimeInput
          id="searchDate"
          type="date"
          value={downtimeDate}
          change={setDowntimeDate}
          label="Journée recherchée"
          className="dateSearch"
        />
        <button className="invisibleButton">
          <FontAwesomeIcon
            icon="fa-solid fa-angle-right"
            className="buttonSearch"
            onClick={() => changeDate("add")}
          >
          </FontAwesomeIcon>
        </button>
        <button className="invisibleButton">
                  <FontAwesomeIcon
                      icon="fa-duotone fa-arrows-rotate"
                      className="buttonRefresh"
                      onClick={() => load()}
                  >
            </FontAwesomeIcon>
        </button>
      </div>
      <DataGrid
        id="datagridHistory"
        dataSource={tableData}
        showBorders={true}
        rowAlternationEnabled={true}
          >
              <HeaderFilter visible={true}/>
        <Paging defaultPageSize={17} />
        <Pager
          visible={true}
          displayMode={"full"}
          showInfo={true}
          showNavigationButtons={true}
        />
        <Column dataField="idDowntime" dataType="int" visible={false} />
        <Column dataField="PlantName" dataType="string" caption="Bâtiment" />
        <Column dataField="ProcessName" dataType="string" caption="Processus" />
        <Column dataField="DivisionName" dataType="string" caption="Division" />
        <Column
          dataField="EquipmentName"
          dataType="string"
          caption="Nom d'équipment"
        />
        <Column dataField="ExtCode" dataType="string" caption="Code externe" />
        <Column
          dataField="ReasonCode"
          dataType="string"
          caption="Code de raison"
        />
        <Column
          dataField="CodeChildReason"
          dataType="string"
          caption="Code de raison spécifique"
        />
        <Column
          dataField="StartHour"
          dataType="time"
          caption="Début de l'arrêt"
          sortOrder="desc"
        />
        <Column dataField="Frequency" dataType="int" caption="Fréquence" />
        <Column dataField="LengthSec" dataType="int" caption="Durée (sec)" />
        <Column dataField="Comment" dataType="string" caption="Commentaire" />
        <Column
          dataField="idSupervisor"
          dataType="string"
          caption="Superviseur"
        />
        <Column type="buttons" width={110}>
          <Button name="openEdit" onClick={(e) => startEditing(e)}>
            <FontAwesomeIcon
              icon="fa-solid fa-pencil"
              className="history-edit-icon"
            />
          </Button>
        </Column>
        <Editing mode="row" allowUpdating={true} />
      </DataGrid>
    </div>
  );
};

export default DowntimeHistoryTable;
