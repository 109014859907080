import React from 'react';
import './LoginContainer.css'

const LoginContainer = ({ children }) => {
    return (
        <div className='containerL'>
            <div className='screen'>
                <div className='screen_content'>
                    { children }
                </div>
                <div className='screen_background'>
                    <span className='screen__background__shape screen__background__shape1'></span>
                </div>
            </div>
        </div>
    );
}

export default LoginContainer;
