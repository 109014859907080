import { useState, useEffect } from "react";
import axios from "axios";
const urlStart = process.env.REACT_APP_URL;
const urlRole = urlStart + "api/APIUser/GetRoles/";
const useAuth = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const IsAuthenticated = () => {
     axios
      .get(urlStart + "api/APIUser/IsAuthenticated/", {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data == true) {
          setLoggedIn(res.data);
          if (sessionStorage.getItem("Role") == null);
          {
            axios
              .get(urlRole, { withCredentials: true })
              .then((res) => sessionStorage.setItem("Role", res.data));
          }
        } else {
          setLoggedIn(false);
        }
        setLoading(false);
      })
      .catch((ex) => {
          setLoggedIn(false);
          setLoading(false);
          setError(ex);
      });
  };

  useEffect(() => {
    IsAuthenticated();
  }, []);

  return { loading, loggedIn, error };
};

export default useAuth;
