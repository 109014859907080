import React from "react";
import { LoadingContextProvider } from "../contexts/LoadingContext";
import PopupLoading from "./PopupLoading";

const LoadingHandler = ({ children }) => {
  return (
    <LoadingContextProvider>
      <PopupLoading>{children}</PopupLoading>
    </LoadingContextProvider>
  );
};

export default LoadingHandler;
