import React, { useContext, useEffect } from "react";
import EffectoH1 from "../components/global/EffectoH1";
import ResetPasswordForm from "../components/login/ResetPasswordForm";
import { useNavigate } from "react-router";
import { ViewContext } from "../components/contexts/ViewContext";

const ResetPasswordView = () => {
    const nav = useNavigate();
    const { setView } = useContext(ViewContext);
    useEffect(() => {
        setView("Downtime");
    }, [])

    return (
        <div
            className="mainContainer pl-3 pr-3"
            style={{ padding: " 0 16px 0 16px" }}
        >
            <div className="mainTitle">
                <EffectoH1 caption={"Reset Your Password"} />
            </div>
            <div className="row mt-2">
                <div className="col-md-5">
                    <ResetPasswordForm />
                    <button onClick={() => nav(-1)} className="btn btn-primary">Back Home</button>
                </div>
            </div>
        </div>
    );
};

export default ResetPasswordView;
