import React from "react";
import Select, { components } from "react-select";

const ValueContainer = ({ children, getValue, ...props }) => {
  var values = getValue();
  var valueLabel = "";

  if (values.length > 0)
    valueLabel += props.selectProps.getOptionLabel(values[0]);
  if (values.length > 1) valueLabel += " & " + (values.length - 1) + " more";

  var childsToRender = React.Children.toArray(children).filter(
    (child) =>
      ["Input", "DummyInput", "Placeholder"].indexOf(child.type.name) >= 0
  );

  return (
    <components.ValueContainer {...props}>
      {!props.selectProps.inputValue && valueLabel}
      {childsToRender}
    </components.ValueContainer>
  );
};

const TableMultiSelectWithCheckBox = ({
  items,
  selectedOptions,
  setSelectedOptions,
}) => {
  const handleChange = (options) => {
    setSelectedOptions(options);
  };
  return (
    <Select
      isMulti
      value={selectedOptions}
      onChange={handleChange}
      options={items}
      closeMenuOnSelect={false}
      components={{
        ValueContainer,
      }}
      hideSelectedOptions={false}
    />
  );
};

export default TableMultiSelectWithCheckBox;
