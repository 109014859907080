import React, { useContext, useState } from "react";
import { States } from "./Constants";
import { LoadingContext } from "../../contexts/LoadingContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const urlStart = process.env.REACT_APP_URL;
const url = urlStart + "api/APIWorkSchedule/PostWorkSchedulePeriods";
import TableDeleteContent from "./TableDeleteContent";
import { HasDetail, isFieldsValid, PostChanges } from "./TableHelper";
import TableSelectPauseType from "./TableSelectPauseType";
import TableEditField from "./TableEditField";
import { RessourceEditor } from "../../languages/RessourcesEditor";

const TableEditItemContent = ({
  table,
  data,
  setInEdition,
  state,
  setRefresh,
  Title,
  TitleCss,
  parentIndex,
  indexItems,
  pauseTypes,
}) => {
  const [State, setState] = useState(state);
  const { loading, setLoading } = useContext(LoadingContext);
  const [error, setError] = useState(false);
  const [startDate, setStartDate] = useState(data ? data.StartDate : "");
  const [endDate, setEndDate] = useState(data ? data.EndDate : "");
  const [pauseType, setPauseType] = useState(
    data && data.Details.length != 0 ? data.Details[0].Type : 0
  );
  const [detailEndDate, setDetailEndDate] = useState(
    data && data.Details.length != 0 ? data.Details[0].EndDate : ""
  );
  const [IsDeleting, setIsDeleting] = useState(false);

  const UpdateStartDate = (input) => {
    setStartDate(input.target.value);
  };
  const UpdateEndDate = (input) => {
    setEndDate(input.target.value);
  };
  const UpdatePauseType = (input) => {
    if (input.target.value == 0) {
      setDetailEndDate("");
    }
    setPauseType(input.target.value);
  };
  const UpdateDetailEndDate = (input) => {
    setDetailEndDate(input.target.value);
  };
  const Save = async (e) => {
    if (isFieldsValid(startDate, endDate, pauseType, detailEndDate)) {
      switch (State) {
        case States.IsNew:
          Add();
          break;
        case States.IsModified:
          Modify();
          break;
        case States.IsDeleted:
          Delete();
          break;
        case States.None:
          break;
      }
    } else {
      setError("Veuillez renseignez tous les champs");
    }
  };

  const Delete = async () => {
    var column = table[data.ParentIndex];
    data.State = States.IsDeleted;
    column.Items.forEach((item) => {
      if (item.Index > data.Index) {
        item.State = States.IsModified;
        item.Index = item.Index - 1;
      }
    });
    if (column.ID != 0) {
      setLoading(true);
      await PostChanges(url, column)
        .then(() => {
          CloseAll();
          column.Items.splice(data.Index, 1);
        })
        .catch((err) => {
          setError("Erreur");
        })
        .finally(() => {
          setLoading(false);
          setState(States.None);
        });
    } else {
      column.Items.splice(data.Index, 1);
    }
  };

  const Modify = async () => {
    var column = table[data.ParentIndex];
    var item = column.Items[data.Index];
    item.State = States.IsModified;
    item.StartDate = startDate;
    item.EndDate = endDate;
    if (HasDetail(pauseType, detailEndDate)) {
      if (item.Details.length != 0) {
        item.Details[0].Type = pauseType;
        item.Details[0].StartDate = endDate;
        item.Details[0].EndDate = detailEndDate;
        item.Details[0].Label = pauseTypes[pauseType];
      } else
        item.Details.push({
          Type: pauseType,
          StartDate: endDate,
          EndDate: detailEndDate,
          Label: pauseTypes[pauseType],
        });
    } else {
      item.Details = [];
    }
    setLoading(true);
    await PostChanges(url, column)
      .then(() => {
        item.State = States.None
        CloseAll();
      })
      .catch((err) => {
        setError("Erreur");
      })
      .finally(() => {
        setLoading(false);
        setState(States.None);
      });
  };

  const Add = async () => {
    var column = table[parentIndex];
    column.Items.push({
      Index: indexItems,
      ID: 0,
      ParentIndex: parentIndex,
      StartDate: startDate,
      EndDate: endDate,
      Details: [],
      State: State,
    });
    if (HasDetail(pauseType, detailEndDate)) {
      column.Items[indexItems].Details.push({
        Type: pauseType,
        StartDate: endDate,
        EndDate: detailEndDate,
        Label: pauseTypes[pauseType],
      });
    }
    setLoading(true);
    await PostChanges(url, column)
      .then((res) => {
        if (res.data) {
          var id = res.data[0].id;
          var item = column.Items[indexItems];
          item.ID = id;
          item.State = States.None;
        }
        CloseAll();
      })
      .catch((err) => {
        console.log(err);
        setError("Erreur");
      })
      .finally(() => {
        setLoading(false);
        setState(States.None);
      });
  };

  const CloseAll = () => {
    setInEdition(false);
    setIsDeleting(false);
  };
  const ToggleInEdition = (e) => {
    setInEdition((prev) => !prev);
  };
  const ToggleDeleteConfirmation = (e) => {
    if (!IsDeleting) {
      setState(States.IsDeleted);
    } else {
      setState(States.None);
    }
    setIsDeleting((prev) => !prev);
  };

  if (IsDeleting) {
    return (
      <TableDeleteContent
        ConfirmAction={Delete}
        CancelAction={ToggleDeleteConfirmation}
      />
    );
  }

  return (
    <>
      <div className="edit p-1">
        <div className="edit-exit">
          <RessourceEditor className={TitleCss} dataTranslate={Title}/>
          {/*<p className={TitleCss}>{Title}</p>*/}
        </div>
        <div className="d-block">
          <TableEditField
            value={startDate}
            label={"StartDateField"}
            id={"itemStartDate"}
            type={"time"}
            labelClassName={"table-input-label"}
            className={"table-time-picker"}
            onChange={UpdateStartDate}
          />
          <TableEditField
            value={endDate}
            label={"EndDateField"}
            id={"EndDate"}
            type={"time"}
            labelClassName={"table-input-label mt-1"}
            className={"table-time-picker"}
            onChange={UpdateEndDate}
          />
        </div>
        <hr />
        <div className="mb-1">
          <div className="d-block">
            <TableSelectPauseType
              pauseTypes={pauseTypes}
              pauseType={pauseType}
              label={"PauseField"}
              onChange={UpdatePauseType}
            />
          </div>
          <TableEditField
            value={detailEndDate}
            label={"PauseEndDateField"}
            id={"StartDate"}
            type={"time"}
            labelClassName={"table-input-label mt-1"}
            className={"table-time-picker"}
            onChange={UpdateDetailEndDate}
          />
        </div>
        <div className="edit-item-button mr-3">
          <FontAwesomeIcon
            className="cancel-icon"
            icon="fa-solid fa-arrow-rotate-left"
            onClick={ToggleInEdition}
          />
          {State != States.IsNew && (
            <FontAwesomeIcon
              className="cancel-delete-icon"
              icon={"fa-regular fa-trash-can"}
              onClick={ToggleDeleteConfirmation}
            />
          )}
          <FontAwesomeIcon
            className="confirm-delete-icon"
            icon="fa-solid fa-check"
            onClick={Save}
          />
        </div>
      </div>
      {error && <div className="d-block ml-5 color-red">{error}</div>}
    </>
  );
};

export default TableEditItemContent;
