import React from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { LoadingContext } from "../contexts/LoadingContext";
import { useContext } from "react";
import { RessourceEditor } from "../languages/RessourcesEditor";
LoadingContext;
const urlStart = process.env.REACT_APP_URL;
const url = urlStart + "api/APIWorkSchedule/CreateWorkSchedule";

const PopupAddWorkSchedule = ({ setIsAdding }) => {
  const { loading, setLoading } = useContext(LoadingContext);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [timeShiftBegin, setTimeShiftBegin] = useState("");
  const [timeShiftEnd, setTimeShiftEnd] = useState("");
  const [timeShiftEndWithPause, setTimeShiftEndWithPause] = useState("");

  const UpdateName = (e) => {
    setName(e.target.value);
  };
  const UpdateDesc = (e) => {
    setDescription(e.target.value);
  };
  const UpdateTimeShiftBegin = (e) => {
    setTimeShiftBegin(e.target.value);
  };
  const UpdateTimeShiftEnd = (e) => {
    setTimeShiftEnd(e.target.value);
  };

  const UpdateTimeShiftEndWithPause = (e) => {
    setTimeShiftEndWithPause(e.target.value);
  };

  const InsertWorkSchedule = () => {
    setLoading(true);
    if (
      name != "" &&
      description != "" &&
      timeShiftBegin != "" &&
      timeShiftEnd != ""
    ) {
      axios
        .post(
          url,
          {
            Name: name,
            Description: description,
            TimeShiftBegin: timeShiftBegin,
            TimeShiftEnd: timeShiftEnd,
            TimeShiftEndWithPause: timeShiftEndWithPause,
          },
          { withCredentials: true }
        )
        .then(() => {
          setLoading(false);
          setIsAdding(false);
        });
    }
  };

  return (
    <div className="overlay">
      <div className="popupAddWorkSchedule">
        <FontAwesomeIcon
          className="closeIcon"
          icon={"fas fa-xmark"}
          onClick={() => setIsAdding(false)}
        />

        <div className="addWorkScheduleTitle">
          <RessourceEditor dataTranslate={"addWorkScheduleTitle"} />
        </div>
        <div className="inputField">
          <RessourceEditor dataTranslate={"WorkScheduleName"} />
          <input type={"text"} value={name} onChange={UpdateName}></input>
        </div>
        <div className="inputField">
          <RessourceEditor dataTranslate={"WorkScheduleDescription"} />
          <input
            type={"text"}
            value={description}
            onChange={UpdateDesc}
          ></input>
        </div>
        <div className="inputField">
          <RessourceEditor dataTranslate={"WorkScheduleTimeShiftBegin"} />
          <input
            type={"time"}
            value={timeShiftBegin}
            onChange={UpdateTimeShiftBegin}
          ></input>
        </div>
        <div className="inputField">
          <RessourceEditor dataTranslate={"WorkScheduleTimeShiftEnd"} />
          <input
            type={"time"}
            value={timeShiftEnd}
            onChange={UpdateTimeShiftEnd}
          ></input>
        </div>
        <div className="inputField">
        <RessourceEditor dataTranslate={"WorkScheduleTimeShiftEndWithPause"} />
          <input
            type={"time"}
            value={timeShiftEndWithPause}
            onChange={UpdateTimeShiftEndWithPause}
          ></input>
        </div>
        <div className="edit-item-button mr-3">
          <FontAwesomeIcon
            className="cancel-icon fs-3"
            icon={"fa-solid fa-arrow-rotate-left"}
            onClick={() => setIsAdding(false)}
          />
          <FontAwesomeIcon
            className="confirm-delete-icon fs-3"
            icon={"fa-solid fa-check"}
            onClick={() => InsertWorkSchedule()}
          />
        </div>
      </div>
    </div>
  );
};

export default PopupAddWorkSchedule;
