import React from "react";
import { RessourceEditor } from "../../languages/RessourcesEditor";

const TableEditField = ({
  value,
  label,
  id,
  type,
  className,
  labelClassName,
  onChange,
}) => {
  return (
    <>
      <div className={labelClassName}><RessourceEditor dataTranslate={label} /></div>
      <input
        type={type}
        id={id}
        className={className}
        value={value}
        onChange={onChange}
      />
    </>
  );
};

export default TableEditField;
