import React from "react";

const ViewContainer = ({ children }) => {
  return (
    <div
          className="d-block ViewContainer"
    >
      {children}
    </div>
  );
};

export default ViewContainer;
