import React, { useContext, useEffect } from "react";
import { Routes, Route } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { ViewContext } from "./components/contexts/ViewContext";
import LoginView from "./views/LoginView";
import ForgotPasswordView from "./views/ForgotPasswordView";
import ResetPasswordView from "./views/ResetPasswordView";
import DefaultLayout from "./components/layout/DefaultLayout";
import DashboardView from "./views/DashboardView";
import DashboardUrlView from "./views/DashboardUrlView";
import DatabaseTableView from "./views/DatabaseTableView";
import EquipmentView from "./views/EquipmentView";
import ReasonView from "./views/ReasonView";
import ErrorView from "./views/ErrorView";
import ProtectedRoute from "./authCompoment/ProtectedRoute";
import DowntimeView from "./views/DowntimeView";
import ShiftView from "./views/ShiftView";
import UserView from "./views/UserView";
import MenuManagementView from "./views/MenuManagementView"

const RouteManager = () => {
	const { setView } = useContext(ViewContext);

	useEffect(() => {
		setView("Dashboard");
	}, []);

	return (
		<BrowserRouter>
			<Routes>
				<Route path="Login" element={<LoginView />} />
				<Route path="ForgotPassword" element={<ForgotPasswordView />} />
				<Route path="ResetPassword" element={<ResetPasswordView />} />
				<Route path="/" element={<ProtectedRoute><DefaultLayout /></ProtectedRoute>}>
					<Route index element={<DashboardView />} />
					<Route path="Dashboard" element={<DashboardView />} />
					<Route path="DashboardUrl/:UrlParamDashboardId" element={<DashboardUrlView />} />
					<Route path="DatabaseTable" element={<DatabaseTableView />} />
					<Route path="Equipment" element={<EquipmentView />} />
					<Route path="Reason" element={<ReasonView />} />
					<Route path="Downtime" element={<DowntimeView />} />
					<Route path="Shift" element={<ShiftView />} />
					<Route path="User" element={<UserView />} />
				</Route>
				<Route path="Administration" element={<ProtectedRoute><DefaultLayout /></ProtectedRoute>}>
					<Route path="MenuManagement" element={<MenuManagementView/>} />
				</Route>
				<Route path="*" element={<ErrorView />} />
			</Routes>
		</BrowserRouter>
	);
};

export default RouteManager;
