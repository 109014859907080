import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import "bootstrap/dist/css/bootstrap.css";
import "devextreme/dist/css/dx.light.css";
import "jquery-ui/themes/base/all.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.common.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.light.css";
import "@devexpress/analytics-core/dist/css/dx-querybuilder.css";
import "devexpress-dashboard/dist/css/dx-dashboard.light.css";
import "./index.css";
import "./site.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
