import React from "react";
import "./Field.css";
import {
	Input,
	Submit,
	Button,
	CheckBox,
	CheckboxToggle,
	GroupCheckbox,
	Url,
	CustomSelect
} from "./Index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import Submit from "./Submit";
//import Button from "./Button";
//import CheckBox from "./CheckBox";
//import CheckboxToggle from "./CheckboxToggle";
//import Url from "./Url";
//import CustomSelect from "./CustomSelect";
//import GroupCheckbox from "./GroupCheckbox";

const Field = ({ action,
	field: {
		field_type,
		field_id,
		field_placeholder,
		field_value,
		field_label,
		field_options,
		icon,
		icon_classname,
		field_classname,
		field_label_classname,
		field_container_classname
	},
}) => {
	switch (field_type) {
		case "text":
			return (
				<div className={field_container_classname}>
					{icon && <FontAwesomeIcon className={icon_classname} icon={icon} />}
					<Input
						className={field_classname}
						field_id={field_id}
						field_placeholder={field_placeholder}
						field_value={field_value}
						field_type={field_type}
					/>
				</div>
			);
		case "password":
			return (
				<div className={field_container_classname}>
					<FontAwesomeIcon className={icon_classname} icon={icon} />
					<Input
						className={field_classname}
						field_id={field_id}
						field_placeholder={field_placeholder}
						field_value={field_value}
						field_type={field_type}
					/>
				</div>
			);
		case "submit":
			return (
				<Submit
					className={field_classname}
					field_id={field_id}
					field_value={field_value}
					icon={icon}
					icon_classname={icon_classname}
					container_classname={field_container_classname}
				/>
			);
		case "button":
			return (
				<Button
					className={field_classname}
					field_id={field_id}
					field_value={field_value}
					icon={icon}
					icon_classname={icon_classname}
					container_classname={field_container_classname}
					action={action}
				/>
			);
		case "checkbox":
			return (
				<div className={field_container_classname}>
					<CheckBox
						field_id={field_id}
						field_value={field_value}
						field_label={field_label}
						field_className={field_classname}
						field_label_classname={field_label_classname}
					/>
				</div>
			);
		case "checkboxToggle":
			return (
				<div className={field_container_classname}>
					<CheckboxToggle
						field_id={field_id}
						field_value={field_value}
						field_label={field_label}
						field_className={field_classname}
						field_label_classname={field_label_classname}
					/>
				</div>
			);
		case "url":
			return (
				<div className={field_container_classname}>
					<Url
						field_id={field_id}
						field_placeholder={field_placeholder}
						field_value={field_value}
					/>
				</div>
			);
		case "dropdown":
			return (
				<div className={ field_container_classname}>
					<span className="floating-label">{field_placeholder}</span>
					<CustomSelect
						className={field_classname}
						field_id={field_id}
						field_value={field_value}
						field_options={field_options}
					/>
				</div>
			);
		case "groupcheckbox":
			return (
				<div>
					<GroupCheckbox
						className={field_classname}
						field_id={field_id}
						field_value={field_value}
						field_options={field_options}
					/>
				</div>
			);
		default:
			return null;
	}
};

export default Field;
