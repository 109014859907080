import React, { useEffect, useState } from "react";
import { processBatchUpdate, setupColumn } from "./DatabaseTableHelper";
import {
  DataGrid,
  Editing,
  Scrolling,
  SearchPanel,
  Paging,
  Toolbar,
  Item,
  HeaderFilter,
} from "devextreme-react/data-grid";
import DatabaseTitle from "./DatabaseTitle";
const urlStart = process.env.REACT_APP_URL;
const UpdateUrl = urlStart + "api/APITableEditor/Save";

const DatabaseTable = ({
  foreignKeys,
  foreignData,
  tableData,
  columns,
  columnOrder,
  tableName,
  idName,
  setPopupMessage,
  togglePopup,
  setPopupTitle,
  setPopupMessageType,
  setHasChanged,
  children,
}) => {
  const [Columns, setColumns] = useState();

  const hideIdColumn = (columns) => {
    columns[0].visible = false;
    columns.forEach((column) => {
      column.showEditorAlways = true;
    });
  };

  const handleSave = React.useCallback((e) => {
    e.cancel = true;
    if (e.changes.length != 0) {
      e.promise = processBatchUpdate(
        UpdateUrl,
        e.changes,
        e.component,
        tableName,
        idName
      );
    }
    if (e.promise) {
      e.promise
        .then(() => {
          setPopupMessageType("Success");
          setPopupTitle("Success");
          setPopupMessage("The database as succesfully been updated");
          togglePopup();
          setHasChanged(true);
        })
        .catch((error) => {
          setPopupMessageType("Error");
          setPopupTitle(error.response.data.message);
          setPopupMessage(error.response.data.innerMessage);
          togglePopup();
          setHasChanged(true);
        });
    }
  });

  useEffect(() => {
    if (columnOrder !== "") {
      var columnsToSkip = [];
      var orderArray = columnOrder.split(";");
      orderArray.unshift(columns[0]);
      orderArray.forEach((v, i) => {
        var columnFounded = columns.find((c) => c.Ordinal_Position == v);
        if (columnFounded != null) {
          orderArray[i] = columnFounded;
          columnsToSkip.push(Number(v));
        }
      });
      columns.forEach((c) => {
        if (c !== null) {
          if (columnsToSkip.indexOf(c.Ordinal_Position) == -1 && c.Ordinal_Position != 1) {
            orderArray.push(c);
          }
        }
      });

      var columnsSetup = setupColumn(orderArray, foreignKeys, foreignData);
      setColumns(columnsSetup);
    } else {
      var columnsSetup = setupColumn(columns, foreignKeys, foreignData);
      setColumns(columnsSetup);
    }
  }, [columns, foreignData]);

  return (
    <DataGrid
      id="datagrid"
      height={"90vh"}
      dataSource={tableData}
      allowColumnResizing={true}
      rowAlternationEnabled={true}
      showBorders={true}
      customizeColumns={hideIdColumn}
      columnAutoWidth={true}
      onSaving={handleSave}
      remoteOperations={true}
      repaintChangesOnly={true}
      columns={Columns}
    >
      <Toolbar>
        <Item location={"before"}>{children}</Item>
        <Item location="center">
          <DatabaseTitle
            title={tableName ? tableName.Name.replace("dbo.", "") : ""}
          ></DatabaseTitle>
        </Item>
        <Item location={"after"} name={"addRowButton"} />
        <Item location={"after"} name={"saveButton"} />
        <Item location={"after"} name={"revertButton"} />
        <Item location={"after"} name={"searchPanel"} />
      </Toolbar>
      <HeaderFilter visible={true} />
      <Paging enabled={false} />
      <Editing
        mode="batch"
        allowUpdating={true}
        allowDeleting={true}
        allowAdding={true}
        selectTextOnEditStart={true}
        startEditAction={"click"}
        useIcons={true}
      />
      <Scrolling mode="virtual" />
      <SearchPanel visible={true} highlightCaseSensitive={true} />
    </DataGrid>
  );
};

export default DatabaseTable;
