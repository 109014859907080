import React, { useState, useEffect } from "react";
import axios from "axios";
import EffectoH1 from "../global/EffectoH1";
import "./Downtime.css";
import EffectoInput from "../global/EffectoInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  GetSpecificTableData,
  loadTableData,
  PostSpecificTableData,
} from "../databaseTable/DatabaseTableHelper";

const DowntimeForm = ({
    setPopupMessage,
    togglePopup,
    setPopupTitle,
    setPopupMessageType,
    toggleUpdateOpen,
    dataDowntimeUpdate,
    setDowntimeInserted,
    formType,
}) => {
    const urlStart = process.env.REACT_APP_URL;
    const urlTableData = urlStart + "api/APITableEditor/GetTableData/";
    const urlPlantData = urlStart + "api/APITableEditor/GetPlantData/";
    const urlUpdateDowntime = urlStart + "api/APITableEditor/UpdateDowntime/";
    const urlInsertDowntime = urlStart + "api/APITableEditor/InsertDowntime/";
    const urlUsers = urlStart + "api/APITableEditor/GetUsers/";
    const hasWindow = typeof window !== "undefined";

    const [loading, setLoading] = useState(true);
    const [equipmentTable, setEquipmentTable] = useState("");
    const [reasonTable, setReasonTable] = useState("");
    const [childReasonTable, setChildReasonTable] = useState("");
    const [userTable, setUserTable] = useState("");
    const [plantTable, setPlantTable] = useState("");
    const [processTable, setProcessTable] = useState("");
    const [isOpen, setIsOpen] = useState(true);
    const { height, width } = getWindowDimensions();

    const [saveEquipment, setSaveEquipment] = useState("");


    function getWindowDimensions() {
        const width = hasWindow ? window.innerWidth : null;
        const height = hasWindow ? window.innerHeight : null;
        return {
            width,
            height,
        };
    }

    const toggleOpen = () => {
        setIsOpen((value) => !value);
    };

    const today = new Date();
    const defaultDate = new Date(today).toISOString().split("T")[0]; // yyyy-mm-dd


    const [selectedEquipment, setSelectedEquipment] = useState(
        dataDowntimeUpdate ? { value: dataDowntimeUpdate.idEquipment } : ""
    );
    const [selectedReason, setSelectedReason] = useState(
        dataDowntimeUpdate ? { value: dataDowntimeUpdate.idReason } : ""
    );
    const [selectedChildReason, setSelectedChildReason] = useState(
        dataDowntimeUpdate ? { value: dataDowntimeUpdate.idChildReason } : ""
    );
    const [selectedPlant, setSelectedPlant] = useState("");
    const [selectedProcess, setSelectedProcess] = useState("");
    const [selectedSupervisor, setSelectedSupervisor] = useState(
        dataDowntimeUpdate ? { label: dataDowntimeUpdate.idSupervisor } : ""
    );
    const [downtimeDate, setDowntimeDate] = useState(
        dataDowntimeUpdate ? dataDowntimeUpdate.StartDate.substr(0, 10) : ""
    );
    const [downtimeCommentary, setDownTimeCommentary] = useState(
        dataDowntimeUpdate ? dataDowntimeUpdate.Comment ? dataDowntimeUpdate.Comment : "" : ""
    );
    const [frequency, setFrequency] = useState(
        dataDowntimeUpdate ? dataDowntimeUpdate.Frequency : "1"
    );
    const [duration, setDuration] = useState(
        dataDowntimeUpdate ? dataDowntimeUpdate.LengthSec : "1"
    );
    const [startingHour, setStartingHour] = useState(
        dataDowntimeUpdate ? dataDowntimeUpdate.StartHour : ""
    );

    const EquipmentTableName = "tbl_equipment";
    const ReasonTableName = "tbl_reason";

    useEffect(() => {
        //if (!selectedSupervisor) {

        //setSelectedSupervisor("");
        //}
        //if (!selectedEquipment) {
        //  setSelectedEquipment("");
        //  setSelectedPlant("");
        //  setSelectedProcess("");
        //}
        //  if (!selectedReason) {
        //  setSelectedReason("");
        //}
        //  if (!selectedChildReason) {
        //  setSelectedChildReason("");
        // }
        //  if (!selectedPlant) {
        //  setSelectedPlant("");
        //  }
        //  if (!selectedProcess) {
        //      console.log(selectedProcess)
        //      setSelectedProcess("");
        //  }
    }, [
        selectedSupervisor,
        selectedEquipment,
        selectedReason,
        selectedChildReason,
    ]);

    async function load() {
        var condition = "";
        if (selectedProcess && selectedPlant && selectedProcess.value != "empty" && selectedPlant.value != "empty") {
            condition = "WHERE p.idPlant = " + selectedPlant.value + " AND e.idProcess = " + selectedProcess.value;
        }
        else if (selectedProcess && selectedProcess.value != "empty") {
            condition = "WHERE e.idProcess = " + selectedProcess.value;
        }
        else if (selectedPlant && selectedPlant.value != "empty") {
            condition = "WHERE p.idPlant = " + selectedPlant.value
        }
        var tableData = await loadTableData(
            urlPlantData,
            EquipmentTableName,
            condition
        );
        if (tableData) {
            setEquipmentTable(tableData.data);
        }
        var usersData = await GetSpecificTableData(urlUsers);
        if (usersData) {
            setUserTable(usersData.data);
        }
        var plantsData = await loadTableData(
            urlTableData,
            "tbl_Plant",
            ""
        );
        if (plantsData) {
            setPlantTable(plantsData.data)
        }
        var conditionProcess = ""
        if (selectedPlant && selectedPlant.value != "empty") {
            conditionProcess = "WHERE p.idPlant = " + selectedPlant.value
        }

        var processData = await loadTableData(
            urlTableData,
            "tbl_Process",
            conditionProcess
        );
        if (processData) {
            setProcessTable(processData.data)
        }
    }
    async function loadOnSelectedEquipment() {

        setChildReasonTable("");
        setSelectedReason("");
        setSelectedChildReason("");
        if (selectedEquipment.value != "empty") {
            const reasonCondition =
                "WHERE idEquipment = " + selectedEquipment.value + " AND idParent IS NULL";
            var reasonData = await loadTableData(
                urlTableData,
                ReasonTableName,
                reasonCondition
            );
            if (reasonData) {
                setReasonTable(reasonData.data);
            }

            const plantCondition = "WHERE idEquipment = " + selectedEquipment.value;
            var plantData = await PostSpecificTableData(
                urlPlantData,
                "tbl_plant",
                plantCondition
            );
            if (plantData) {
                if (!selectedPlant) {
                    setSelectedPlant({ value: plantData.data[0].idPlant, label: plantData.data[0].PlantName });
                }
                if (!selectedProcess) {
                    setSelectedProcess({ value: plantData.data[0].idProcess, label: plantData.data[0].ProcessName })
                }
            }
        }
        else {
            setSaveEquipment("")
        }
    }
    async function loadOnSelectedReason() {
        setSelectedChildReason("");
        setChildReasonTable("");
        if (selectedReason.value != undefined) {
            const tableName = "tbl_Reason";
            const condition = "WHERE idParent = " + selectedReason.value;
            var childReasonData = await loadTableData(
                urlTableData,
                tableName,
                condition
            );
            if (childReasonData) {
                setChildReasonTable(childReasonData.data);
            }
        }
    }
    async function Setup() {
        if (formType == "insert") {
            setDowntimeDate(defaultDate);
            let minute;
            if (today.getMinutes() == 0) {
                minute = "00:00";
            } else if (today.getMinutes() <= 9) {
                minute = "0" + today.getMinutes().toString();
            } else {
                minute = today.getMinutes().toString() + ":00";
            }

            if (today.getHours() <= 9) {
                setStartingHour("0" + today.getHours().toString() + ":" + minute);
            } else {
                setStartingHour(today.getHours().toString() + ":" + minute);
            }
        }
    }

    useEffect(() => {
        load();
        Setup();
        setLoading(false);
    }, []);

    useEffect(() => {
        if (selectedEquipment) {
            loadOnSelectedEquipment();
            if (dataDowntimeUpdate) {
                setSelectedReason(dataDowntimeUpdate.idReason);
            }
        }
    }, [selectedEquipment]);

    useEffect(() => {
        if (selectedReason) {
            loadOnSelectedReason();
            if (dataDowntimeUpdate) {
                setSelectedChildReason(dataDowntimeUpdate.idChildReason);
            }
        }
    }, [selectedReason]);

    useEffect(() => {
        load();
        if (selectedEquipment && selectedProcess && equipmentTable.includes(selectedEquipment)) {
            setSaveEquipment(selectedEquipment)
        }
        setSelectedEquipment("")  
    }, [selectedPlant, selectedProcess]);

    useEffect(() => {
        setSelectedEquipment(saveEquipment)   
    },[saveEquipment])

    useEffect(() => {
        if (selectedProcess && selectedProcess.value != "empty" && !selectedPlant) {
            var process = processTable.find(x => x.idProcess == selectedProcess.value)
            var plant = plantTable.find(x => x.idPlant == process.idPlant)
            setSelectedPlant({ value: plant.idPlant, label: plant.PlantName })
        }
    }, [selectedProcess])

    useEffect(() => {
        if (!selectedPlant || selectedPlant.value == "empty") {
            setSelectedProcess("")
        }
    }, [selectedPlant])

  function Save(url) {
      async function save() {

      const data = {
        idDowntime: dataDowntimeUpdate?.idDowntime,
        idEquipment: selectedEquipment?.value,
        idReason: selectedReason.value ? selectedReason.value:selectedReason,
        idChildReason: selectedChildReason?.value,
        startDate: downtimeDate,
        duration: duration,
        startHour: startingHour,
        idSupervisor: selectedSupervisor?.label,
        frequency: frequency == null ? 1 : frequency,
        commentary: downtimeCommentary,
        oldStartDate: dataDowntimeUpdate?.StartDate,
        oldDuration: dataDowntimeUpdate?.LengthSec,
        oldIdEquipment: dataDowntimeUpdate?.idEquipment,
        oldStartHour: dataDowntimeUpdate?.StartHour,
        };
      axios
        .post(url, data, { withCredentials: true })
        .then(() => {
          setPopupMessageType("Success");
          setPopupTitle("Success");
          setPopupMessage("The database as succesfully been updated");
          togglePopup();
          setDowntimeInserted(true);
          if (formType == "insert") {
            resetInput();
          }
          if (formType == "update") {
            toggleUpdateOpen();
          }
        })
        .catch((error) => {
          if (error.response) {
            setPopupMessageType("Error");
            setPopupTitle(error.response.data.message);
            setPopupMessage(error.response.data.innerMessage);
            togglePopup();
            if (formType == "update") {
              resetInput();
            }
          }
        });
    }
    save();
  }

    function resetInput() {
    setSelectedPlant("");
    setSelectedProcess("");
    setSelectedEquipment(
      dataDowntimeUpdate ? dataDowntimeUpdate.idEquipment  : ""
    );
    setSelectedReason(
      dataDowntimeUpdate ? dataDowntimeUpdate.idReason : ""
    );
    setSelectedChildReason(
      dataDowntimeUpdate ? dataDowntimeUpdate?.idChildReason : ""
    );
      
    setSelectedSupervisor(
      dataDowntimeUpdate ? { label: dataDowntimeUpdate.idSupervisor } : ""
    );
        setDownTimeCommentary(dataDowntimeUpdate?.Comment ? dataDowntimeUpdate.Comment  : "");
    setFrequency(dataDowntimeUpdate ? dataDowntimeUpdate.Frequency : "1");
    setDuration(dataDowntimeUpdate ? dataDowntimeUpdate.LengthSec : "1");
    setDowntimeDate(
      dataDowntimeUpdate
     ? dataDowntimeUpdate.StartDate.substr(0, 10)
     : defaultDate
    );

    setSelectedPlant(
       dataDowntimeUpdate ? { value: dataDowntimeUpdate.idPlant } : ""
    )

    setSelectedProcess(
        dataDowntimeUpdate ? { value: dataDowntimeUpdate.idProcess } : ""
    )
    if (dataDowntimeUpdate) {
      setStartingHour(dataDowntimeUpdate ? dataDowntimeUpdate.StartHour : "");
    } else {
      let minute;
      if (today.getMinutes() == 0) {
        minute = "00";
      } else if (today.getMinutes() <= 9) {
        minute = "0" + today.getMinutes().toString();
      } else {
        minute = today.getMinutes().toString();
      }

      if (today.getHours() <= 9) {
        setStartingHour("0" + today.getHours().toString() + ":" + minute);
      } else {
        setStartingHour(today.getHours().toString() + ":" + minute);
      }
    }
  }

  return (
    <div className={formType == "update" ? "downtimeUpdateForm" : ""}>
      <div className="topContainer">
        <div className="title">
          <EffectoH1
            caption={
              formType == "update" ? "Update Downtime" : "Insert Downtime"
            }
          />
        </div>
        {formType == "insert" ? (
          <>
            {isOpen && formType == "insert" ? (
              <button className="invisibleButton">
                <FontAwesomeIcon
                  icon="fa-solid fa-angle-up"
                  className="history-toggle-icon"
                  onClick={toggleOpen}
                />
              </button>
            ) : (
              <button className="invisibleButton">
                <FontAwesomeIcon
                  icon="fa-solid fa-angle-down"
                  className="history-toggle-icon"
                  onClick={toggleOpen}
                />
              </button>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
      {(isOpen && formType == "insert") || formType == "update" ? (
        <div className="form">
          <div className="leftSection">
            <div className="top">
              {!loading && userTable && (
                <EffectoInput
                  id="supervisor"
                  type="select"
                  value={selectedSupervisor}
                  change={setSelectedSupervisor}
                  label="Contremaitre"
                  table={userTable}                                
                />
              )}
            </div>
            <div className="mid">
              <EffectoInput
                id="building"
                type="select"
                table={plantTable}      
                value={selectedPlant}
                change={setSelectedPlant}
                label="Bâtiment"
                valueName="idPlant"
                displayName="PlantName"
              />
              <EffectoInput
                id="process"
                type="select"
                table={processTable}
                value={selectedProcess}
                change={setSelectedProcess}
                label="Process"
                valueName="idProcess"
                displayName="ProcessName"
              />
            </div>
            <div className="low">
              {!loading && equipmentTable && (
                <EffectoInput
                  id="equipment"
                  type="select"
                  value={selectedEquipment}
                  table={equipmentTable}
                  change={setSelectedEquipment}
                  label="Équipement*"
                  valueName="idEquipment"
                  displayName="EquipmentName"
                />
              )}
            </div>
          </div>

          <div className="rightSection">
            <div className="top">
              <EffectoInput
                id="downtimeDate"
                type="date"
                value={downtimeDate}
                change={setDowntimeDate}
                label="Date temps d'arrêt*"
              />
            </div>
            <div className="removeMargin mid">
              <div className="firstRow">
                <EffectoInput
                  id="reasonCode"
                  type="select"
                  value={selectedReason}
                  disabled={
                    selectedEquipment &&
                    selectedEquipment.value != "empty" &&
                    reasonTable &&
                    reasonTable.length > 0
                      ? ""
                      : "disabled"
                  }
                  table={reasonTable}
                  change={setSelectedReason}
                  label="Code d'arrêt*"
                  valueName="idReason"
                  displayName="ReasonCode"
                />
                <EffectoInput
                  id="specificReasonCode"
                  type="select"
                  value={selectedChildReason}
                  disabled={
                    selectedReason &&
                    selectedReason.value != "empty" &&
                    childReasonTable &&
                    childReasonTable.length > 0
                      ? ""
                      : "disabled"
                  }
                  table={childReasonTable}
                  change={setSelectedChildReason}
                  label={
                    width > 1180
                      ? "Code d'arrêt spécifique (optionnel)"
                      : "Code spécifique"
                  }
                  valueName="idReason"
                  displayName="ReasonCode"
                />
              </div>
              <div className="secondRow">
                <EffectoInput
                  id="startingTime"
                  type="time"
                  value={startingHour}
                  change={setStartingHour}
                  step="2"
                  label={width > 1180 ? "Heure début (Format 24h)*" : "Heure"}
                />
                <EffectoInput
                  id="frequency"
                  type="number"
                  value={frequency}
                  change={setFrequency}
                  label="Fréquence*"
                />
                <EffectoInput
                  id="duration"
                  type="number"
                  value={duration}
                  change={setDuration}
                  label="Durée (sec)*"
                />
              </div>
            </div>
            <div className="low">
              <EffectoInput
                id="commentary"
                type="textarea"
                value={downtimeCommentary}
                change={setDownTimeCommentary}
                label="Commentaire"
              />
              <div className="buttonContainer containerDiv">
                <button
                  onClick={() =>
                    formType == "update"
                      ? Save(urlUpdateDowntime)
                      : Save(urlInsertDowntime)
                  }
                  className="submit"
                >
                  {formType == "update" ? "Update" : "Soumettre"}
                </button>
                <button
                  onClick={() => resetInput()}
                  className="resetInputButton"
                >
                  <FontAwesomeIcon icon="fa-solid fa-arrow-rotate-left">
                    -
                  </FontAwesomeIcon>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DowntimeForm;
