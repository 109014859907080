import React from "react";
import { SideBarItem } from "./index";
import { MenuSelectedContextProvider } from "../contexts/MenuSelectedContext";

const SideBarItemList = ({ items, IsSortable }) => {
	return (
		<MenuSelectedContextProvider first={items[0]}>
			{items.length >= 1 && items.map((i, key) => {
				return (
					<SideBarItem
						key={key}
						url={i.controller}
						menuType={i.menuType}
						elementViewerID={i.elementViewerID}
						caption={i.caption}
						icon={i.icon}
						IsSortable={IsSortable}
					/>
					
				);
			})}
		</MenuSelectedContextProvider>
	);
};

export default SideBarItemList;
