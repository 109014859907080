import * as Model from "devexpress-dashboard/model";
import dxCheckBox from "devextreme/ui/check_box";
import dxTextBox from "devextreme/ui/text_box";

// 1. Model
var RealTimeProperty = {
  ownerType: Model.DashboardItem,
  propertyName: "RealTime",
  defaultValue: "False",
  valueType: "boolean",
};

var RefreshTimeProperty = {
  ownerType: Model.DashboardItem,
  propertyName: "RefreshTime",
  valueType: "text",
};
Model.registerCustomProperty(RealTimeProperty);
Model.registerCustomProperty(RefreshTimeProperty);

// 2. Viewer

// 3. Designer
function onCustomizeSections(args) {
  var dashboardItem = args.dashboardItem;
  if (
    dashboardItem instanceof Model.CardItem ||
    dashboardItem instanceof Model.GaugeItem ||
    dashboardItem instanceof Model.TextBoxItem
  ) {
    args.addSection({
      title: "Effecto",
      items: [
        {
          dataField: RealTimeProperty.propertyName,
          template: function (args, element) {
            var chxContainer = document.createElement("div");
            new dxCheckBox(chxContainer, {
              value: getValue(dashboardItem, RealTimeProperty.propertyName),
              text: "RealTime",
              onValueChanged: function (e) {
                setValue(dashboardItem, e.value, RealTimeProperty.propertyName);
              },
            });
            return chxContainer;
          },
        },
        {
          dataField: RefreshTimeProperty.propertyName,
          template: function (args, element) {
            var txtContainer = document.createElement("div");
            new dxTextBox(txtContainer, {
              placeholder: "Refresh time in sec ...",
              value: getValue(dashboardItem, RefreshTimeProperty.propertyName),
              onValueChanged: function (e) {
                setValue(
                  dashboardItem,
                  e.value,
                  RefreshTimeProperty.propertyName
                );
              },
            });
            return txtContainer;
          },
        },
      ],
    });
  }
}

// 4. Event Subscription
export class RealTimeExtension {
  name = "RealTime";
  constructor(dashboardControl) {
    this.dashboardControl = dashboardControl;
  }

  start() {
    var viewerApiExtension = this.dashboardControl.findExtension("viewer-api");
    if (viewerApiExtension) {
      //viewerApiExtension.on('itemWidgetOptionsPrepared', onItemWidgetOptionsPrepared);
    }
    var optionsPanelExtension =
      this.dashboardControl.findExtension("item-options-panel");
    if (optionsPanelExtension) {
      optionsPanelExtension.on("customizeSections", onCustomizeSections);
    }
  }
  stop() {
    var viewerApiExtension = this.dashboardControl.findExtension("viewer-api");
    if (viewerApiExtension) {
      //viewerApiExtension.off('itemWidgetOptionsPrepared', onItemWidgetOptionsPrepared);
    }
    var optionsPanelExtension =
      this.dashboardControl.findExtension("item-options-panel");
    if (optionsPanelExtension) {
      optionsPanelExtension.off("customizeSections", onCustomizeSections);
    }
  }
}

//5. SetValue
function setValue(realTimeItem, value, property) {
  return realTimeItem.customProperties.setValue(
    property,
    JSON.stringify(value)
  );
}

function getValue(dashboardItem, property) {
  var ret = [];
  try {
    ret = JSON.parse(dashboardItem.customProperties.getValue(property));
  } catch (e) {
    ret = [];
  }
  return ret;
}
