import React from "react";
import EffectoH1 from "../components/global/EffectoH1";
import ForgotPasswordForm from "../components/login/ForgotPasswordForm";
import { useNavigate } from "react-router";

const ForgotPasswordView = () => {
    const nav = useNavigate();

  return (
    <div
      className="mainContainer pl-3 pr-3"
      style={{ padding: " 0 16px 0 16px" }}
    >
      <div className="mainTitle">
        <EffectoH1 caption={"Forgot Your Password"} />
      </div>
      <div className="container">
        <p className="mt-4">
          Enter the username linked to your account and we'll send you a
          link to reset your password.
        </p>
      </div>
      <div className="row mt-2">
        <div className="col-md-5">
          <ForgotPasswordForm />
          <button onClick={() => nav(-1)} className="btn btn-primary">Back Home</button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordView;
