import React, { useState, useEffect } from "react";
import MenuViewer from './MenuViewer';
import { GetMenuForClient } from './MenuManagementHelper.js';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'
import '../../dragableItem/menuPage.css'

//import  '../../../menuManager/js/treeMenuSortable.js';
//import  '../../../menuManager/css/style.css';

const MenuContainer = () => {
	const urlStart = process.env.REACT_APP_URL;

	const [menu, getMenu] = useState(null);
	const [loading, setLoading] = useState(true);

	async function load() {
		//var menuLoad = await GetMenuForClient();
		getMenu([]);
		//if (menuLoad) {
		//	getMenu(menuLoad.data);
		//	console.log(menuLoad.data);
		//}
	};

	useEffect(() => {
		load();
		setLoading(false);
	}, []);

	return (
		<DndProvider backend={HTML5Backend}>
			{menu != null ? <MenuViewer menuLoaded={menu} /> : <></>}
		</ DndProvider>
			
	);
}

export default MenuContainer;