
const urlStart = process.env.REACT_APP_URL;

export const SELECTABLE_ITEM = "selectableItem";
export const Level1 = "level1";
export const Level2 = "level2";
export const Level3 = "level3";


//async function loadAllNode() {
//    var result = await axios.get(urlStart + "/api/APIUser/GetMenuAllNode" , {
//            withCredentials: true,
//        });
//        return result;
//    }

//Allow to manage different items in our drag anmd drop
/*export const SELECTABLE_ITEMS = await loadAllNode();*/

    //[
//    {
//        id: 1,
//        type: SELECTABLE_ITEM,
//        component: {
//            type: "selectableItem",
//            content : "test"
//        }
//    }, {
//        id: 2,
//        type: SELECTABLE_ITEM,
//        component: {
//            type: "selectableItem",
//            content: "test1"
//        }
//    }
   
//];
