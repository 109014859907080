import React, { useState } from "react";
import { MenuDropDownGroup } from "./index";

const MenuDropDown = ({ data, selected }) => {
  const [lang, setLang] = useState(sessionStorage.getItem("Lang"));

  /*useEffect(() => {
    if (selected) {
      translate();
    }
  }, [selected]);*/
  
  return (
   /* <>
      {selected && (*/
        <div className={`dropdown-menu ${selected ? "" : "d-none"}`}>
          {data.menu.map((data, key) => {
            return (
              <MenuDropDownGroup
                key={key}
                data={data}
                lang={lang}
                setLang={setLang}
              />
            );
          })}
        </div>
      /*)}
    </>*/
  );
};

export { MenuDropDown };
