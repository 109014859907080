import React, { useState } from 'react'

const DashboardContext = React.createContext({
	dashboardId: "",
	setDashboardId: (dashboardId) => { },
})

const DashboardContextProvider = ({ children }) => {
	const [dashboardId, setDashboardId] = useState("");
	
	return <DashboardContext.Provider value={{ dashboardId: dashboardId, setDashboardId:setDashboardId }} >
				{children}
			</DashboardContext.Provider>
}

export { DashboardContext, DashboardContextProvider }