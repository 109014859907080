import React from "react";
import "../../Css/sidemenu.css";
import { SideBarMenu } from "./index";


const AppSideBar = ({ Visibility, menu }) => {
    return (
        <div id="leftMenu" className={` ${ Visibility ? "" : "d-none"}`}>
        <div className="sidebar-brand d-md-flex "> BI ready </div>
            <div className="sidebar-nav">
                <ul className="sidebar sidebar-dark">
                    {menu.length > 0 ?
                        <SideBarMenu data={menu} />
                        :
                        <></>
                    }
                </ul>
            </div>
      </div>
    );
};

export default React.memo(AppSideBar);
