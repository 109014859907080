import React, { useState, useEffect, useContext } from "react";
import { AppTopNavBar } from "../topBar/index";
import { AppSideBar } from "../sidebar/index";
import "./../../Css/header.css";
import LoadingHandler from "../global/LoadingHandler";
import { RessourcesContext } from "../contexts/RessourcesContext";
import { Navigate, Outlet } from "react-router";
import { DashboardContext } from "../contexts/DashboardContext";
import useAxios from "../hooks/useAxios";
const urlStart = process.env.REACT_APP_URL;
const url = urlStart + "Home/GetJsonCompleteMenu";
const urlVisibility = urlStart + "Home/GetMenuVisibility";
import "./DefaultLayout.css";

const DefaultLayout = () => {
  const { response, error:MenuError } = useAxios({ url, method: "get" });
  const { response: visible } = useAxios({ url: urlVisibility, method: "get" });
  const [sideBarVisible, setSidebarVisibility] = useState();
  const { lang, setLang } = useContext(RessourcesContext);
  const { dashboardId, setDashboardId } = useContext(DashboardContext);
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    if (response !== null) {
      setMenu(response);

      if (response[0].lNodeChildrens.length > 0) {
        setDashboardId(response[0].lNodeChildrens[0].elementViewerID);
      } else {
        setDashboardId(response[1].lNodeChildrens[0].elementViewerID);
      }
    }
  }, [response]);

  useEffect(() => {
    if (visible !== null) {
      if (visible === "True") {
        setSidebarVisibility(false);
      } else {
        setSidebarVisibility(true);
      }
    }
  }, [visible]);

  useEffect(() => {
    if (lang == null) {
      var LangStored = sessionStorage.getItem("Lang");
      if (LangStored != null) {
        setLang(LangStored);
      } else {
        sessionStorage.setItem("Lang", "en-En");
        setLang("en-En");
      }
    }
  }, []);

  //if (MenuError) {
  //  return <Navigate to={"/Error"}/>
  //}

  return (
    <LoadingHandler>
      <div className="fullPageContainer">
        <AppSideBar Visibility={sideBarVisible} menu={menu} />
        <div className="rightPage">
          <AppTopNavBar OnSidebarVisibility={setSidebarVisibility} />
          <div className="mainContent">
            {/*children*/}
            <Outlet />
          </div>
        </div>
      </div>
    </LoadingHandler>
  );
};

export default DefaultLayout;
