import React, { useState } from "react";

const MenuSelectedContext = React.createContext({
  item: "",
  setSelectedMenuItem: (item) => {},
});

const MenuSelectedContextProvider = ({ children, first }) => {
  var firstOpenPage = "";
  if (first != null) {
    if (first.elementViewerID != null) {
      firstOpenPage = first.elementViewerID;
    } else if (first.controller != null) {
      firstOpenPage = first.controller;
    } else {
      firstOpenPage = "";
    }
  }
  const [item, setSelectedMenuItem] = useState(firstOpenPage);

  return (
    <MenuSelectedContext.Provider
      value={{ item: item, setSelectedMenuItem: setSelectedMenuItem }}
    >
      {children}
    </MenuSelectedContext.Provider>
  );
};

export { MenuSelectedContext, MenuSelectedContextProvider };
