import React, { useContext, useState } from "react";
import { DashboardControl } from "devexpress-dashboard-react";
import { DashboardContext } from "./contexts/DashboardContext";
import { GaugeRangesExtension } from "./dashboardExtension/GaugeRangesExtension";
import { ChartConstantLinesExtension } from "./dashboardExtension/ChartConstantLinesExtension";
import { RealTimeExtension } from "./dashboardExtension/RealTimeExtension";
import axios from "axios";
import { useParams } from 'react-router-dom';

const url = process.env.REACT_APP_URL + "api/dashboards";
const getDashboardUrl =
  process.env.REACT_APP_URL + "Dashboard/GetDashboardRefreshTimeInMin/";

async function GetDashboard(id) {
  if (id) {
    var result = axios
      .post(getDashboardUrl, JSON.stringify(id), {
        withCredentials: true,
        headers: { "content-type": "application/json" },
      })
      .catch((error) => console.log(error));

    return result;
  }
  return null;
}

function onBeforeRender(e) {
  var dashboardControl = e.component;
  dashboardControl.remoteService.beforeSend = function (jqXHR, settings) {
    settings.xhrFields = { withCredentials: true };
  };
  dashboardControl.registerExtension(
    new GaugeRangesExtension(dashboardControl)
  );
  dashboardControl.registerExtension(
    new ChartConstantLinesExtension(dashboardControl)
  );
  dashboardControl.registerExtension(new RealTimeExtension(dashboardControl));
  //dashboardControl.registerExtension(new ChartConstantLinesExtension(dashboardControl));
  //dashboardControl.option("extensions.viewerApi.onDashboardTitleToolbarUpdated", customizeTitleToolbar);
  //  dashboardControl.registerExtension(new DevExpress.Dashboard.DashboardPanelExtension(dashboardControl));
  //dashboardControl.registerExtension(new DevExpress.Dashboard.Designer.TextBoxItemEditorExtension(dashboardControl));
}
const Dashboard = () => {


  const { UrlParamDashboardId } = useParams();
  const { dashboardId } = useContext(DashboardContext);
  const [intervalIds, setIntervalIds] = useState([]);
  const updateIntervalIds = (newItem) => {
    setIntervalIds((ids) => [...ids, newItem]);
    };

  function ClearRealTimeRefresh() {
    intervalIds.forEach((id) => {
      clearInterval(id);
    });
    setIntervalIds([]);
  }
  function RefreshDashboard(e) {
    var result = GetDashboard(dashboardId);
    ClearRealTimeRefresh();
    var dashboardControl = e.component;
    var dashboard = dashboardControl.dashboard();
    var items = dashboard.items();

    items.forEach((item) => {
      var properties = item.customProperties.getInfo();
      if (
        properties.filter((i) => i.propertyName == "RealTime") &&
        properties.filter((i) => i.propertyName == "RefreshTime")
      ) {
        var isRealTime = item.customProperties.RealTime();
        var refreshTime = item.customProperties.RefreshTime();
        if (isRealTime) {
          RealTimeRefresh(item.componentName(), refreshTime);
        }
      }
    });
    result.then((res) => {
      if (res) {
        var refreshTimeInMin = res.data;
        RefreshDataSource(refreshTimeInMin);
      }
    });

    function RealTimeRefresh(itemName, timeInSec) {
      var time = parseInt(timeInSec.replace('"', ""));
      if (!isNaN(time) && time != 0) {
        var intervalId = window.setInterval(function () {
          dashboardControl.refresh(itemName);
        }, time * 1000);
        updateIntervalIds(intervalId);
      }
    }
    function RefreshDataSource(refreshTimeInMin) {
      if (!isNaN(refreshTimeInMin) && refreshTimeInMin != 0) {
        var intervalId = window.setInterval(() => {
          dashboardControl.reloadData();
        }, refreshTimeInMin * 1000 * 60);
        updateIntervalIds(intervalId);
      }
    }
  }
    return (
        <>
    <DashboardControl
      style={{ height: "100%", paddingBottom: "15px"}}
      endpoint={url}
      dashboardId={UrlParamDashboardId ? UrlParamDashboardId : dashboardId}
      workingMode="Viewer"
      onBeforeRender={onBeforeRender}
      onDashboardInitialized={RefreshDashboard}
      
            ></DashboardControl>
    </>
  );
};

export default Dashboard;
