import React, { useState, useEffect } from "react";
import "devextreme/dist/css/dx.light.css";
import "../../Css/datagrid.css";
import {
  DataGrid,
  Editing,
  Paging,
  Toolbar,
  Item,
  Pager,
} from "devextreme-react/data-grid";
import { processBatchUpdate, loadTableData } from "./DatabaseTableHelper";

const ReasonTypeTable = ({
  HaveChanged,
  setHaveChanged,
  setPopupMessage,
  togglePopup,
  setPopupTitle,
  setPopupMessageType,
}) => {
  const urlStart = process.env.REACT_APP_URL;
  const UpdateUrl = urlStart + "api/APITableEditor/Save/";
  const urlTableData = urlStart + "api/APITableEditor/GetTableData/";
  const [tableData, setTableData] = useState(null);

  async function load() {
    const tableName = "tbl_ReasonType";
    const condition = "";
    var tableData = await loadTableData(urlTableData, tableName, condition);
    if (tableData) {
      setTableData(tableData.data);
    } else {
      setTableData({ idType: null, Name: null, Description: null });
    }
  }

  useEffect(() => {
    if (HaveChanged != null && HaveChanged.reasonType) {
      load();
      setHaveChanged({ reasonType: false, reason: false });
    }
  }, [HaveChanged]);

  const onSaving = React.useCallback((e) => {
      e.cancel = true;
    if (
      (e.changes.length != 0 && e.changes[0].type == "remove") ||
      e.changes[0].data["Name"]?.trim() != ""
    ) {
      e.promise = processBatchUpdate(
        UpdateUrl,
        e.changes,
        e.component,
        "tbl_ReasonType",
        "idType"
      );

      if (e.promise) {
        e.promise
          .then(() => {
            setPopupMessageType("Success");
            setPopupTitle("Success");
            setPopupMessage("The database as succesfully been updated");
            togglePopup();
            setHaveChanged({ reasonType: true, reason: true });
          })
          .catch((error) => {
            setPopupMessageType("Error");
            setPopupTitle(error.response.data.message);
            setPopupMessage(error.response.data.innerMessage);
            togglePopup();
            setHaveChanged({ reasonType: true, reason: true });
          });
      }
    }
  });

  const hideIdColumn = (columns) => {
    //columns[0].showInColumnChooser = true;
    columns[0].visible = false;
  };

  return (
    <>
      <DataGrid
        id="datagrid"
        dataSource={tableData}
        keyExpr={tableData != null? Object.keys(tableData[0])[0] : ""}
        customizeColumns={hideIdColumn}
        showBorders={true}
        rowAlternationEnabled={true}
        onSaving={onSaving}
      >
        <Paging defaultPageSize={5} />
        <Pager
          visible={true}
          displayMode={"full"}
          showInfo={true}
          showNavigationButtons={true}
        />
        <Toolbar>
          <Item location={"after"} name={"addRowButton"} />
          <Item location={"after"} name={"saveButton"} />
          <Item location={"after"} name={"revertButton"} />
        </Toolbar>
        <Editing
          mode="row"
          allowUpdating={true}
          allowAdding={true}
          allowDeleting={true}
          selectTextOnEditStart={true}
          useIcons={true}
          confirmDelete={true}
        />
      </DataGrid>
    </>
  );
};

export default ReasonTypeTable;
