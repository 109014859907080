import React, { useContext } from "react";
import { SideBarDashboardItem, SideBarViewItem } from './index'
import { MenuSelectedContext, MenuSelectedContextProvider } from "../contexts/MenuSelectedContext";


const SideBarItem = ({ elementViewerID, caption, icon, url, menuType, IsSortable }) => {
	const { item:SelectedNode } = useContext(MenuSelectedContext);

	return (<MenuSelectedContextProvider>
		<li className={IsSortable ? "cursor tree-branch branch-level-2" : "nav-item cursor"} >
			{IsSortable ?
				<>plop</>
				:
				elementViewerID ?
				<SideBarDashboardItem elementViewerID={elementViewerID} caption={caption} icon={icon} SelectedNode={SelectedNode} />
				:
				<SideBarViewItem caption={caption} icon={icon} url={url} SelectedNode={SelectedNode} />
			}
		</li>
	</MenuSelectedContextProvider>
	);
};

export default SideBarItem;
