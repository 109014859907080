import React, { useState, useContext, useEffect } from "react";
import ViewContainer from "../components/container/ViewContainer";
import ReasonTable from "../components/databaseTable/ReasonTable";
import ReasonTypeTable from "../components/databaseTable/ReasonTypeTable"
import GlobalPopup from "../components/global/GlobalPopup/GlobalPopup"
import { ViewContext } from "../components/contexts/ViewContext";
import EffectoH1 from "../components/global/EffectoH1";
import "../Css/tableView.css";

const ReasonView = () => {
    const [HaveChanged, setHaveChanged] = useState({ reasonType: true, reason: true })

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupMessage, setPopupMessage] = useState("");
    const [popupTitle, setPopupTitle] = useState("");
    const [popupMessageType, setPopupMessageType] = useState("");
    const { setView } = useContext(ViewContext);

    const togglePopup = () => {
        setIsPopupOpen((value) => !value);
    }

    useEffect(() => {
        setView("Reason");
    }, [])

    return (
        <ViewContainer>
            {isPopupOpen && <GlobalPopup Message={popupMessage} handleClose={togglePopup} Title={popupTitle} MessageType={popupMessageType} />}
            <div className="tableReasonContainer">
                <div className="title">
                    <EffectoH1 caption="Reason table" />
                </div>
                <ReasonTable HaveChanged={HaveChanged} setHaveChanged={setHaveChanged} setPopupMessage={setPopupMessage} togglePopup={togglePopup} setPopupTitle={setPopupTitle} setPopupMessageType={setPopupMessageType} />
            </div>
            <div className="tableTypeReasonContainer">
                <div className="title">
                    <EffectoH1 caption="ReasonTypeTable" />
                </div>
                <ReasonTypeTable HaveChanged={HaveChanged} setHaveChanged={setHaveChanged} setPopupMessage={setPopupMessage} togglePopup={togglePopup} setPopupTitle={setPopupTitle} setPopupMessageType={setPopupMessageType} />
            </div>
           
        </ViewContainer>
    );
}

export default ReasonView;