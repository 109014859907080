import React, { useState, useEffect } from "react";
import "devextreme/dist/css/dx.light.css";
import "../../Css/datagrid.css";
import { DataGrid, Editing, Scrolling } from "devextreme-react/data-grid";
import { processBatchUpdate, loadTableData } from "./DatabaseTableHelper";

const EquipmentTable = ({
  setPopupMessage,
  togglePopup,
  setPopupTitle,
  setPopupMessageType,
}) => {
  const urlStart = process.env.REACT_APP_URL;
  const UpdateUrl = urlStart + "api/APITableEditor/Save/";
  const urlTableData = urlStart + "api/APITableEditor/GetTableData/";
  const [HaveChanged, setHaveChanged] = useState(true);
  const [tableData, setTableData] = useState();
  const tableName = "tbl_equipment";

  async function load(tableName) {
    var tableData = await loadTableData(urlTableData, tableName);
    if (tableData) {
      setTableData(tableData.data);
    }
  }

  useEffect(() => {
    if (HaveChanged) {
      load(tableName);
      setHaveChanged(false);
    }
  }, [HaveChanged]);

  const hideIdColumn = (columns) => {
    columns[0].showInColumnChooser = true;
    columns[0].visible = false;
    columns[1].showInColumnChooser = true;
    columns[1].visible = false;
    columns[2].allowEditing = false;
    columns[3].allowEditing = false;
    columns[4].allowEditing = false;
  };

  const onSaving = React.useCallback((e) => {
    e.cancel = true;
    if (e.changes.length != 0) {
      e.promise = processBatchUpdate(
        UpdateUrl,
        e.changes,
        e.component,
        "tbl_Equipment",
        "idEquipment"
      );
    }
    if (e.promise) {
      e.promise
        .then(() => {
          setPopupMessageType("Success");
          setPopupTitle("Success");
          setPopupMessage("The database as succesfully been updated");
          togglePopup();
          setHaveChanged(true);
        })
        .catch((error) => {
          setPopupMessageType("Error");
          setPopupTitle(error.response.data.message);
          setPopupMessage(error.response.data.innerMessage);
          togglePopup();
          setHaveChanged(true);
        });
    }
  });

  return (
    <>
      <DataGrid
        id="datagrid"
        height={"50vh"}
        dataSource={tableData}
        customizeColumns={hideIdColumn}
        showBorders={true}
        rowAlternationEnabled={true}
        onSaving={onSaving}
      >
        <Editing
          mode="batch"
          allowUpdating={true}
          selectTextOnEditStart={true}
        />
        <Scrolling mode="virtual" />
      </DataGrid>
    </>
  );
};

export default EquipmentTable;
