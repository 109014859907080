import React from 'react';
import { MenuDropDownItemList } from './index';

const MenuDropDownGroup = ({ data }) => {
		return (<>
			<div className="dropdown-header py-2">
				{data.title}
			</div>
			<MenuDropDownItemList items={data.menu}/>
		</>)
}

export { MenuDropDownGroup }