import React, { useContext, useState, useEffect } from "react";
import { getValuebyDataTranslate } from "./RessourcesManager";
import { RessourcesContext } from "../contexts/RessourcesContext";
const RessourceEditor = ({ dataTranslate, className, children }) => {
  const [value, setValue] = useState("");
  const { isRessourcesEditing, lang, ressources } =
    useContext(RessourcesContext);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    var value = getValuebyDataTranslate(ressources, dataTranslate);
    setValue(value);
  }, [lang, ressources]);

  return (
    <div className={className}>
      {children}
      {/*{!isRessourcesEditing ? (*/}
      <span
        onChange={setValue}
        className={!isRessourcesEditing ? "" : "d-none"}
        data-translate={dataTranslate}
      >
        {value}
      </span>
      {/*) : (*/}
      <input
        type="text"
        onChange={handleChange}
        value={value}
        data-translate={dataTranslate}
        className={`inputTranslate ${
          isRessourcesEditing ? "d-block" : "d-none"
        }`}
      />
      {/*)}*/}
    </div>
  );
};

export { RessourceEditor };
