import React, { useState } from 'react'

const ViewContext = React.createContext({
	view: "Dashboard",
	setView: (view) => { },
})

const ViewContextProvider = ({ children }) => {
	const [view, setView] = useState("Dashboard");

	return <ViewContext.Provider value={{ view: view, setView:setView }} >
				{children}
			</ViewContext.Provider>
}

export { ViewContext, ViewContextProvider }