import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Effectoa } from "../global/indexGlobal"
const urlStart = window.location.href


const MenuDropDownUserItem = ({ title, icon }) => {
	return (
	<Effectoa
		className="dropdown-item clickable d-flex align-items-center"
		caption={title}
		onClick={() => {
			window.open(urlStart + "User", "_blank");
		}}
	>
		<FontAwesomeIcon icon={icon} className="icon me-2" />
		</Effectoa>
		)
}

export { MenuDropDownUserItem };