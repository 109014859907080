import React, { useState, useEffect } from "react";
import "devextreme/dist/css/dx.light.css";
import "../../Css/datagrid.css";
import {
  processBatchUpdate,
  loadTableData,
  loadForeignData,
} from "./DatabaseTableHelper";
import {
  DataGrid,
  Editing,
  Scrolling,
  Paging,
  Toolbar,
  Item,
  Column,
  Lookup,
  MasterDetail,
  Pager,
} from "devextreme-react/data-grid";
import ReasonChildrenTable from "./ReasonChildrenTable";

const ReasonTable = ({
  HaveChanged,
  setHaveChanged,
  setPopupMessage,
  togglePopup,
  setPopupTitle,
  setPopupMessageType,
}) => {
  const urlStart = process.env.REACT_APP_URL;
  const urlTableData = urlStart + "api/APITableEditor/GetTableData";
  const urlForeignData = urlStart + "api/APITableEditor/GetForeignData";
  const [tableData, setTableData] = useState();
  const [foreignData, setForeignData] = useState();
  const tableName = "tbl_Reason";
  const condition = "WHERE idParent IS NULL";

    const [foreignDataEquipment, setForeignDataEquipment] = useState();
    const [foreignDataType, setForeignDataType] = useState();

  async function load(table, condition) {
      var tableData = await loadTableData(urlTableData, table, condition);
    if (tableData) {
      setTableData(tableData.data);
      }
    var foreignDataData = await loadForeignData(urlForeignData, table);
    if (foreignDataData) {
        setForeignData(foreignDataData.data);
        for (var i = 0; i < foreignDataData.data.length; i++) {
            if (foreignDataData.data[i].Rows[1]["idReason"] == undefined) {
                if (foreignDataData.data[i].Rows[1]["idType"] != undefined) {
                    setForeignDataType(foreignDataData.data[i].Rows)
                }
                if (foreignDataData.data[i].Rows[1]["idEquipment"] != undefined) {
                    setForeignDataEquipment(foreignDataData.data[i].Rows)
                }
            }           
        }
      }
  }

  useEffect(() => {
    if (HaveChanged != null && HaveChanged.reason) {
      load(tableName, condition);
      setHaveChanged({ reasonType: false, reason: false });
    }
  }, [HaveChanged]);

  const onSaving = React.useCallback((e) => {
    e.cancel = true;
    if (e.changes.length != 0) {
      e.promise = processBatchUpdate(
        urlStart + "api/APITableEditor/SaveReason",
        e.changes,
        e.component,
        "tbl_Reason",
        "idReason"
      );
    }
    if (e.promise) {
      e.promise
        .then(() => {
          setPopupMessageType("Success");
          setPopupTitle("Success");
          setPopupMessage("The database as succesfully been updated");
          togglePopup();
          setHaveChanged({ reasonType: false, reason: true });
        })
        .catch((error) => {
          setPopupMessageType("Error");
          setPopupTitle(error.response.data.message);
          setPopupMessage(error.response.data.innerMessage);
          togglePopup();
          setHaveChanged({ reasonType: false, reason: true });
        });
    }
  });

  return (
    <>
      <DataGrid
        id="datagrid"
        height={"60vh"}
        dataSource={tableData}
        showBorders={true}
        rowAlternationEnabled={true}
        onSaving={onSaving}
      >
        <Paging defaultPageSize={20} />
        <Pager
          visible={true}
          displayMode={"full"}
          showInfo={true}
          showNavigationButtons={true}
        />
        <Toolbar>
          <Item location={"after"} name={"addRowButton"} />
          <Item location={"after"} name={"saveButton"} />
          <Item location={"after"} name={"revertButton"} />
        </Toolbar>
        <Column dataField="ReasonCode" dataType="string" />
        <Column dataField="idEquipment" caption="Equipment">
           {foreignDataEquipment != null && (
            <Lookup
              dataSource={foreignDataEquipment}
              displayExpr="EquipmentName"
              valueExpr="idEquipment"
            />
          )}
        </Column>
        <Column dataField="Description" dataType="string" />
        <Column dataField="Category" dataType="int" />
        <Column dataField="ExtCode" dataType="string" />
        <Column dataField="idReasonType" caption="Type">
                  {foreignDataType != null && (
                      <Lookup
                          dataSource={foreignDataType}
                          displayExpr="Name"
                          valueExpr="idType"
                          allowClearing={true}
                      />
                  )}
        </Column>
        <Column dataField="Origin" dataType="int" />
        <Column dataField="Actif" />
        <Editing
                  mode="row"
                  allowUpdating={true}
                  allowDeleting={true}
                  allowAdding={true}
                  selectTextOnEditStart={true}
                  useIcons={true}
                  confirmDelete={true}
                  
        />
        <Scrolling mode="virtual" />
        <MasterDetail enabled={true} component={ReasonChildrenTable} />
      </DataGrid>
    </>
  );
};

export default ReasonTable;
