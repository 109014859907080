import React from "react";
import { useDrag } from "react-dnd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Effectoa from '../global/Effectoa';

const SelectableItem = ({ data, setEditing, setNodeId }) => {
    const [{ opacity }, drag] = useDrag({
        item: data,
        type: "selectableItem",
        collect: monitor => ({
            opacity: monitor.isDragging() ? 0.4 : 1
        })
    });

    return (
        <div className="draggableItem" ref={drag} style={{ opacity }}>
            {data.icon && 
                <FontAwesomeIcon icon={data.icon} className="icon icon-lg mr-1" />
            }
            <span className="ml-1">
                {data.name}
             </span>
            <Effectoa
                className="clickable d-flex align-items-center"
                onClick={() => {
                   /* setLoading(true);*/
                    setNodeId(data.id);
                    //MenuCreate();
                    setEditing(true);
                    /*console.log(nodes);*/
                }}
                caption=""
                clickOverride={true}
            >
                <FontAwesomeIcon icon="fa-solid fa-pen-to-square" className=" icon icon-lg align-right" />
            </Effectoa>

        </div>
    );
};
export default SelectableItem;
