import React, { useState } from "react";
import "./Downtime.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EffectoH1 from "../global/EffectoH1"

const DowntimeHistoryContainer = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);



    const toggleOpen = () => {
        setIsOpen((value) => !value);
    }

    return (
        <div className="downtimeHistoryContainer">
            <div className="topContainer">
                <div className="title">
                    <EffectoH1 caption="Downtime History" />
                </div>
                {isOpen ? <button className="invisibleButton"><FontAwesomeIcon icon="fa-solid fa-angle-up" className="history-toggle-icon" onClick={toggleOpen} /></button> : <button className="invisibleButton"><FontAwesomeIcon icon="fa-solid fa-angle-down" className="history-toggle-icon" onClick={toggleOpen} /></button>}
            </div>
            <div className="tableHistory">
                {isOpen ? children : <></>}
            </div>
        </div>
    );
}

export default DowntimeHistoryContainer;