import React, { useContext, useState, useEffect } from "react";
import Select from "react-select";
import { FormContext } from "../../contexts/FormContext";

const CustomSelect = ({
	field_id,
	field_value,
	field_options,
	field_classname,
}) => {
	const { handleChange, error } = useContext(FormContext);
	const [field_error, setField_error] = useState(null);

	useEffect(() => {
		if (error !== null && error.length != 0) {
			var isInError = false;
			error.forEach((err) => {
				if (err.field_id == field_id) {
					setField_error(err);
					isInError = true;
				}
			});
			if (isInError != true) {
				setField_error(null);
			}
		} else {
			setField_error(null);
		}
	}, [error]);
	return (
		<>
			<Select
				id={field_id}
				value={field_value}
				options={field_options}
				onChange={(event) => handleChange(field_id, event)}
				className="select-ddl"
			/>
			{field_error && (
				<span className="text-danger d-block field-validation-error">
					{field_error.error_message}
				</span>
			)}
		</>
	);
};

export default CustomSelect;
