import React from 'react';

const Url = ({ field_id, field_placeholder, field_value }) => {
    return (
        <a id={field_id} href={field_value}>
            <span>{field_placeholder}</span>
        </a>
    );
}

export default Url;
