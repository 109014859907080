import React, { useState, useEffect } from "react";
import axios from "axios";
const urlStart = process.env.REACT_APP_URL;
const url = urlStart + "Administration/Home/GetJsonRessources";
const initialLang = sessionStorage.getItem("Lang");

const RessourcesContext = React.createContext({
  isRessourcesEditing: false,
  setEditingRessources: (isRessourcesEditing) => {},
  lang: "",
  setLang: () => {},
  ressources: null,
  setRessources: (ressources) => {},
  error: "",
});

const RessourcesContextProvider = ({ children }) => {
  const [isRessourcesEditing, setEditingRessources] = useState(false);
  const [lang, setLang] = useState(initialLang);
  const [ressources, setRessources] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    async function getRessources() {
      if (lang == null) {
        sessionStorage.setItem("Lang", "en-En");
        setLang("en-En");
        await axios.post(url, lang, {
          withCredentials: true,
          headers: { "Content-Type": "application/json" },
        }).then((res) => {
          setRessources(res.data)
        }).catch((err) => {
          setError(err);
        });
      } else {
        await axios.post(url, lang, {
          withCredentials: true,
          headers: { "Content-Type": "application/json" },
        }).then((res) => {
          setRessources(res.data)
        }).catch((err) => {
          setError(err);
        });
      }
    }
    if (lang != null) {
      getRessources();
    }
  }, [lang]);

  return (
    <RessourcesContext.Provider
      value={{
        isRessourcesEditing: isRessourcesEditing,
        setEditingRessources: setEditingRessources,
        lang: lang,
        setLang: setLang,
        ressources: ressources,
        setRessources: setRessources,
        error:error,
      }}
    >
      {children}
    </RessourcesContext.Provider>
  );
};

export { RessourcesContext, RessourcesContextProvider };
