import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuDropDown } from '../menuDropDown/index';

const TopBarDropdown = ({ icon, onClick, classname, dropDownName, dropDownMenu, selectedDropDown, caption}) => {
    const [name, setName] = useState(dropDownName);
    return (
        <> 
            <li className={classname} onClick={() => onClick((prev) => {
                if (prev != name) {
                    return name
                } else {
                    return ""
                }
            })}>
                {icon && <FontAwesomeIcon icon={icon} className="icon icon-lg" />}
                {caption}
            </li>
            <MenuDropDown data={dropDownMenu} selected={selectedDropDown == name}/>
        </>
        
    );
}

export { TopBarDropdown };
