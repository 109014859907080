import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import FormTemplate from "../global/form/FormTemplate";
import fieldsJSON from "./LoginFields.json";
import axios from "axios";
import { ValidateFields } from "./LoginValidationHelper";
import { LoadingContext } from "../contexts/LoadingContext";
const urlStart = process.env.REACT_APP_URL;
const url = urlStart + "api/APIUser/Login/";

const LoginForm = () => {
  const [error, setError] = useState(null);
  const [requestError, setRequestError] = useState(null);
  const [elements, setElements] = useState(null);
  const { loading, setLoading } = useContext(LoadingContext);
  const nav = useNavigate();
  useEffect(() => {
    setElements(fieldsJSON[0]);
  }, []);

  const handleSubmit = (event) => {
    setLoading(true);
    setError(null);
    setRequestError(null);
    event.preventDefault();
    const error = ValidateFields(elements.fields);
    if (error.length == 0) {
      setError(null);
      axios
        .post(
          url,
          {
            username: elements.fields[0]["field_value"],
            password: elements.fields[1]["field_value"],
          },
          { withCredentials: true }
        )
        .then((res) => {
          if (res) {
            elements.fields[0]["field_value"] = "";
            elements.fields[1]["field_value"] = "";
            nav("/");
          }
        })
        .catch((err) => {
          setRequestError({
            statusCode: err.response.request.status,
            message: err.response.data.message,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setError(error);
      setLoading(false);
    }
  };

  return (
    <>
      <FormTemplate
        classNameTitle={"title_login"}
        className={"login"}
        elements={elements}
        setElements={setElements}
        handleSubmit={handleSubmit}
        error={error}
      />
      {requestError && (
        <span className="text-danger d-block error">
          {requestError.message ?? "Erreur"}
        </span>
      )}
    </>
  );
};

export default LoginForm;
