import React, { useContext } from "react";
import { DashboardContext } from "../contexts/DashboardContext";
import { ViewContext } from "../contexts/ViewContext";
import { useNavigate } from "react-router";
import { Effectoa } from "../global/indexGlobal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuSelectedContext } from "../contexts/MenuSelectedContext";

const SideBarDashboardItem = ({ elementViewerID, caption, icon, key, SelectedNode }) => {
  const { setDashboardId } = useContext(DashboardContext);
  const { view } = useContext(ViewContext);
  const { setSelectedMenuItem } = useContext(MenuSelectedContext);
  const nav = useNavigate();

  return (
    <Effectoa
      className={`nav-link ${SelectedNode == elementViewerID ? "active" : ""}`}
      key={key}
      value={elementViewerID}
      onClick={(e) => {
        var value = e.currentTarget.attributes["value"].value
        if (value != null && value.includes("-")) {
          setDashboardId(value);
          if (view != "DashboardView") {
            nav("/");
          }
          setSelectedMenuItem(value);
          /*setItem(e.currentTarget.attributes["value"].value);*/
        } else {
          nav("/Error")
        }
      }}
      caption={caption}
    >
      <FontAwesomeIcon icon={icon} className="nav-icon" />
    </Effectoa>
  );
};

export default SideBarDashboardItem;
