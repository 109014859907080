import React, { useContext, useEffect, useState } from 'react';
import { RessourcesContext } from '../contexts/RessourcesContext.js';
const langues = [
	{ value: 'en-EN', label: 'en-EN' },
	{ value: 'fr-FR', label: 'fr-FR' },
]

const DdlLanguage = () => {
	const [selectValue, setSelectValue] = useState("");
	const { lang, setLang } = useContext(RessourcesContext);

	useEffect(() => {
		if (lang) {
			sessionStorage.setItem("Lang", lang);
			setSelectValue(lang);
		} else {
			sessionStorage.setItem("Lang","en-En");
			setSelectValue("en-En");
		}
	},[lang])

	function changeLang(e) {
		sessionStorage.setItem('Lang', e.target.value);
		setLang(e.target.value);
	}

	return (
		<select id="sLanguages" onChange={changeLang} value={selectValue} className="form-control">
			{langues.map((data, key) => {
				return (<option key={key} value={data.value} label={data.label}/>)
			})}
		</select>
		

	);
}


export { DdlLanguage };