import React, { useState, useEffect, useContext } from 'react'
import UserForm from './UserForm'
import axios from "axios";
import { GetUser } from './UserHelper'

const urlStart = process.env.REACT_APP_URL;
const urlGet = urlStart + "api/APIUser/GetUser/";

const UserContainer = () => {

	const [userInformations, setuserInformations] = useState("");
	async function GetUserData() {
		var result = await axios.get(
			urlGet,
			{
				withCredentials: true,
				headers: { "content-type": "application/json" },
			}
		);
		return result;
	}

	async function load() {
		var User = await GetUser(
		);
		if (User) {
			setuserInformations(User);
		}
	}


	useEffect(() => {
		load();
	}, []);


	return (<div className="page-container">
		<div className="page-left-container">
			<UserForm userInformations={userInformations} />
		</div>
		<div className="page-right-container">
		</div>
	</div>
	)
}

export default UserContainer;