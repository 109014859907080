import axios from 'axios'
const urlStart = process.env.REACT_APP_URL;

async function GetMenuByMenuInformationsID(menuInformationID) {
	var result = await axios.get(urlStart + "api/APIMenuManagement/GetDragableMenu",
		{ params: { menuInformationID: menuInformationID } },
		{ withCredentials: true });
	console.log(result);
	return result;
}
async function loadAllNode() {
	var result = await axios.get(urlStart + "api/APIMenuManagement/GetMenuAllNode", {
        withCredentials: true,
    });
    return result;
}

async function loadClient() {
	var result = await axios.get(urlStart + "api/APIClient/GetClient", {
		withCredentials: true,
	});
	return result;
}

async function GetMenuInformationIDForClient(ClientID) {
	var result = await axios.get(urlStart + "api/APIClient/GetClientMenuInformation",
		{ params: { clientID: ClientID } },
		{ withCredentials: true });
	console.log(result);
	return result;
}

async function GetMenuNodeById(menuNodeID) {
	var result = await axios.get(urlStart + "api/APIMenuManagement/GetMenuNodeById", {
		params: { menuNodeID: menuNodeID },
		withCredentials: true,
	});
	return result;
}


async function SaveDragableMenu(menuItems) {
	console.log("sending data : ", menuItems);
	var IsSaved = false;
	axios
		.post(
			urlStart + "api/APIMenuManagement/SaveMenuClient/",
			 menuItems ,
			{ withCredentials: true }
		)
		.then((res) => {
			if (res) {
				console.log("OK");
				IsSaved = true;
			}
		})
		.catch((err) => {
			console.log(err);
			IsSaved = false;
		})
		.finally(() => {
			//setLoading(false);
			//setSaving(false);
			IsSaved = true;
		});

	return IsSaved;
}
function ValidateFields(fields) {
	var error = [];
	fields.forEach((field) => {
		switch (field["field_id"]) {
			case "Caption":
				if (field["field_value"] !== "") {

				} else {
					error.push({
						field_id: "Caption",
						error_message: "The Caption field is required.",
					});
				}

			case "NodeType":
				if (field["field_value"] !== "") {

				} else {
					error.push({
						field_id: "NodeType",
						error_message: "The NodeType field is required.",
					});
				}



		}
	});
	return error;
}

async function loadMenuInformations() {
	var result = await axios.get(urlStart + "api/APIMenuManagement/loadMenuInformations", {
		withCredentials: true,
	});
	return result;
}
export {
	GetMenuByMenuInformationsID,
	loadAllNode,
	SaveDragableMenu,
	loadClient, 
	ValidateFields,
	GetMenuNodeById,
	GetMenuInformationIDForClient,
	loadMenuInformations
};





