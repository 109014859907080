import React, { useContext, useEffect, useState } from "react";
import "./PopupLoading.css";
import { LoadingContext } from "../contexts/LoadingContext";

const PopupLoading = ({ children }) => {
    const [visible, setVisible] = useState(false);
    const { loading } = useContext(LoadingContext);

    useEffect(() => {
        setVisible(loading);
    }, [loading]);

  return (
    <>
      { visible && <div className="overlay">
        <div className="popup-loading">
          <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>}
      {children}
    </>
  );
};

export default PopupLoading;
